
$font-stack: 'Montserrat', sans-serif;
$primaryLightBgColor: #efefef;
$primaryDarkBgColor: #212121;
$primaryBlue: #007FD5;
$blueDark: #0064a7;
$neutralLightest: #f8f8f8;
$primaryTextColor: #696969;
$neutralMidDark: #adadad;
$neutralDark: #464646;
$fdRed: #D50000;
$fdGreen: #2F8400;


/* SCREEN SIZE */

$mobile-small: 280px;
$mobile-large: 640px;
$tablet: 768px;
$desktop-small: 1024px;
$desktop-large: 1441px;
$desktop-Xlarge: 1680px;
