@import '../../../constants';
@import '../../../buttons';

.login__contentBox {
    width: 100vw;
    height: auto;
    background-color: $primaryLightBgColor;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;

    .login__contentBox-header {
        width: 100%;
        height: 80px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 16px;

        @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
            padding-top: 46px;
            height: 80px;
        }

        @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
            padding-top: 24px;
            height: 80px;
        }

        .loginScreen-askLogin {
            text-align: center;
            font-family: $font-stack;
            font-size: 13px;
            color: $primaryTextColor;
            position: absolute;
            right: 64px;
        
            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                right: 24px;
            }
        
            .loginScreen-askLoginButton {
              font-weight: 600;
              color: $primaryBlue;
              cursor: pointer;
            }
        
          }
    }

    

    .login__containerBox {
        width: 100%;
        height: auto;
        min-height: calc(100vh - 102px);
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: flex-start;
        padding-top: 56px;

        
    
        .login__containerBox-promoBox {
     
            background-color: $primaryDarkBgColor;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 32px;
            padding: 16px 64px;
    
            h3 {
               
                font-size: 20px;
                color: $neutralLightest;
                font-weight: 600;
                text-align: center;
            }

            span {
                font-size: 20px;
                color: $primaryBlue;
                font-weight: 600;
            }

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                width: 90%;
                padding: 16px 16px;
            }


        }

       
        .login__containerBox-formBox {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            color: $primaryTextColor;
            background-color: $neutralLightest;
            height: auto;
            border-radius: 20px;
            box-shadow: 0 20px 30px rgba(0, 0, 0, .1);
            padding: 20px 40px 40px 40px;
            margin-bottom: 8px;

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                width: 90%;
                padding: 16px 16px 32px 16px;
                border-radius: 10px;
            }
    
            .loginScreen-form {
                width: 100%;
                height: auto;
                display: flex;
                flex-flow: column wrap;
                align-items: flex-start;
                justify-content: center;
            
                @media screen and (max-width: $mobile-large) {
                  width: 100%;
                }
            
                @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
                  width: 450px;
                }

    
                .loginScreen-textBelowInputs-container {
                    width: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 8px;
                
                    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                      width: 450px;
                    }
                }

                .LoginScreen-textBelowInputs {
                    margin-top: 8px;
                    margin-bottom: 48px;
                    width: 450px;
                    line-height: 16px;
                    font-weight: 600;
                    font-size: 11px;
                    color: $primaryBlue;
                    text-align: left;
                    border: none;
                    font-family: $font-stack;
                    cursor: pointer;
                  
                    @media screen and (max-width: $mobile-large) {
                        width: 90%;
                      }
                  
                    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                    width: 90%;
                    }
                  
                  }

              
            
            }
        }

        .LoginScreen-asklogin {
            width: 80%;
            text-align: center;
            font-family: $font-stack;
            font-size: 13px;
            color: $primaryTextColor;
            margin-top: 40px;
            margin-bottom: 32px;
        
            @media screen and (max-width: $mobile-large) {
              width: 100%;
              margin-bottom: 24px;
            }
        
            .LoginScreen-askloginButton {
              font-weight: 600;
              color: $primaryBlue;
              cursor: pointer;
            }
        
          }
        
          .loginScreen-askLogin.one {
            width: 100%;
          }
    
        .login__containerBox-information {
            font-size: 11px;
            color: $primaryTextColor;
            width: 50%;
            text-align: center;
            margin-top: 24px;
            margin-bottom: 24px;
            line-height: 1.5;

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                margin-bottom: 40px;
                padding: 0px 24px;
                margin-top: 16px;
                width: 100%;
            }
        }
    }
}