@import '../../../constants';
@import '../../../buttons';

.register__contentBox {
    width: 100vw;
    height: auto;
    background-color: $primaryLightBgColor;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;

    .register__contentBox-header {
        width: 100%;
        height: 80px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 32px;

        .register__containerBox-freedrumLogoContainer{
          width: 700px;
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
          position: relative;

          @media screen and (min-width: $mobile-large) and (max-width: $tablet)  {
            width: 520px;
          }

          @media screen and (max-width: $mobile-large)  {
            width:100vw;
          }

          .freedrumStudioLogo{
            width: 200px;
  
            @media screen and (max-width: $mobile-large)  {
              width:50%;
              margin-right: 24px;
            }
          }
  
          .amazonLogo {
            width: 100px;
  
            @media screen and (max-width: $mobile-large)  {
              width:25%;
            }
          }
        }

        

        @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
            padding-top: 32px;
            height: 80px;
        }

        @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
            padding: 32px 16px 0px 16px;
            height: 80px;
            justify-content: space-between;
        }

        .RegisterScreen-askLogin {
            text-align: center;
            font-family: $font-stack;
            font-size: 13px;
            color: $primaryTextColor;
            position: absolute;
            right: 64px;
        
            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                right: 24px;
            }
        
            .RegisterScreen-askLoginButton {
              font-weight: 600;
              color: $primaryBlue;
              cursor: pointer;
            }
        
          }
    }

    

    .register__containerBox {
        width: 100%;
        min-height: 800px;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        margin-top:56px;

        .register__containerBox-title {
            color: $neutralDark;
            font-size: 40px;
            font-weight: 500;
            margin-bottom: 16px;

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                padding: 0px 16px;
                text-align: center;
                margin-top: 0px;
            }
            
        }
    
        .register__containerBox-promoBox {
     
            background-color: $primaryDarkBgColor;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            padding: 16px 64px;
    
            h3 {
               
                font-size: 20px;
                color: $neutralLightest;
                font-weight: 600;
                text-align: center;
            }

            span {
                font-size: 20px;
                color: $primaryBlue;
                font-weight: 600;
            }

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                width: 90%;
                padding: 16px 16px;
            }

        }

        .register__containerBox-text {
          margin-bottom: 32px;
          width: 100%;
          text-align: center;
          color: $primaryTextColor;
          line-height: 1.7;
          font-size: 18px;

          @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
            padding: 0px 16px;
           }

          @media screen and (min-width: $mobile-large) and (max-width: $tablet)  {
             width: 80%;
          }
        }
    
        .register__containerBox_formBox {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            color: $primaryTextColor;
            background-color: $neutralLightest;
            height: auto;
            border-radius: 20px;
            box-shadow: 0 20px 30px rgba(0, 0, 0, .1);
            padding: 20px 40px 40px 40px;
            margin-bottom: 8px;

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                width: 90%;
                padding: 16px 16px 32px 16px;
                min-width: auto;
            }

            @media screen and (min-width: $tablet) {
              min-width: 700px;
          }
    
            .RegisterScreen-form {
                width: 100%;
                height: auto;
                display: flex;
                flex-flow: column wrap;
                align-items: flex-start;
                justify-content: center;
            
                @media screen and (max-width: $mobile-large) {
                  width: 100%;
                }
            
                @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
                  width: 100%;
                }
    
                .RegisterScreen-textBelowInputs-container {
                    width: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 4px;
                
                    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                      width: 450px;
                    }
                }
                .RegisterScreen-textBelowInputs {
                    color: $primaryTextColor;
                    width: 90%;
                    line-height: 16px;
                    font-size: 11px;
                    margin-left: 8px;
                
                    @media screen and (max-width: $mobile-large) {
                      width: 90%;
                    }
                
                    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                      width: 90%;
                    }
                  }
                
                  .RegisterScreen-textBelowInputs.password {
                    width: 100%;
                    margin-top: 4px;
                    margin-left: 0px;
                  }
    
                  .RegisterScreen-wrongPasswordIcon {
                    margin-right: 6px;
                    color: $fdRed;
                    position: relative;
                    top: 2px;
                  }
    
                  .RegisterScreen-termsAndConditions {
                    font-family: $font-stack;
                    color: $primaryTextColor;
                    width: 100%;
                    line-height: 1.5;
                    margin-top: 24px;
                    margin-bottom: 24px;
                    font-size: 13px;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                
                    @media screen and (max-width: $mobile-large) {
                      width: 100%;
                    }
                
                    .RegisterScreen-termsAndConditions__button {
                      border: none;
                      font-family: $font-stack;
                      color: $primaryTextColor;
                      font-size: 13px;
                      text-align: left;
                      line-height: 1.5;
                
                    }
                
                    .RegisterScreen-termsAndConditions__label {
                      position: relative;
                      cursor: pointer;
                      padding-left: 0px;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                      width: 100%;
                      display: flex;
                      flex-flow: row wrap;
                      justify-content: flex-start;
                      align-items: center;
                      position: relative;
                    }
                
                    .RegisterScreen-termsAndConditions__label input[type="checkbox"] {
                      clip: rect(0 0 0 0);
                      padding: 0;
                      width: 1px;
                      height: 1px;
                      overflow: hidden;
                      position: absolute;
                      top: -2px;
                      left: -42px;
                      border: 0;
                    }
                
                    .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom {
                      display: block;
                      background-color: $neutralLightest;
                      box-shadow: inset -2px -2px 5px rgba(white, 1),
                      inset 2px 2px 5px rgba(black, 0.1);
                      border-radius: 3px;
                      border: 2px solid white;
                    }
                
                    .checkbox-custom:after {
                      content: "";
                      position: absolute;
                      display: none;
                    }
                
                    /* Show the checkmark when checked */
                    .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom:after {
                      display: block;
                    }
                
                    .RegisterScreen-termsAndConditions__label .checkbox-custom:after {
                      position: relative;
                      margin: auto;
                      top: 4px;
                      width: 5px;
                      height: 10px;
                      border: solid $primaryBlue;
                      border-width: 0 3px 3px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg);
                    }
                
                
                    .RegisterScreen-termsAndConditions__checkbox {
                      position: relative;
                      top: -9px;
                      margin-right: 10px;
                      border: none;
                      width: 20px;
                    }
                
                    .checkbox-custom {
                      width: 25px;
                      height: 25px;
                      display: block;
                      background-color: $neutralLightest;
                      box-shadow: -2px -2px 5px rgba(white, 1),
                      2px 2px 5px rgba(black, 0.1);
                      border-radius: 3px;
                      margin-right: 16px;
                      background-size: cover;
                      border: 1px solid #696969;
                    }
                
                    .RegisterScreen-termsAndConditions__label > p {
                      width: 85%;

                      @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                        width: 75%;
                        }
                    }
                
                
                    .RegisterScreen-termsAndConditions__textLink {
                      color: $primaryBlue;
                    }
                
                  }
    
                  .RegisterScreen-registerButton {
                    @include ctaBtn;
                    margin: auto;
                    margin-top: 16px;
                
                
                    @media screen and (max-width: $mobile-large) {
                      width: 100%;
                    }
                  }
                
                  .RegisterScreen-registerButton:hover {
                    background: $blueDark;
                  }
                
                
                  .RegisterScreen-registerButton-Disable {
                    @include ctaBtn-Disable;
                    margin: auto;
                
                
                    @media screen and (max-width: $mobile-large) {
                      width: 100%;
                    }
                  }
            
            }
        }

        .RegisterScreen-askLogin {
          width: 80%;
          text-align: center;
          font-family: $font-stack;
          font-size: 16px;
          color: $primaryTextColor;
          margin-bottom: 46px;
      
          @media screen and (max-width: $mobile-large) {
            width: 100%;
            margin-bottom: 32px;
          }
      
          .RegisterScreen-askLoginButton {
            font-weight: 600;
            color: $primaryBlue;
            cursor: pointer;

            a {
              font-size: 16px;
            }
          }


      
        }
      
        .RegisterScreen-askLogin.one {
          width: 100%;
        }
    
        .register__containerBox-information {
            font-size: 11px;
            color: $primaryTextColor;
            text-align: center;
            margin: 0px 0px 32px ;
            line-height: 1.5;
            width: 700px;

            @media screen and (max-width: $mobile-large) {
                margin-bottom: 24px;
                padding: 0px 16px;
                margin-top: 16px;
                width: 100%;
            }

            @media screen and (min-width: $mobile-large) and (max-width: $tablet)  {
              width: 80%;
           }
        }
    }
}