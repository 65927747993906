@import '../../../../constants';
@import '../../../../buttons';

.EditProfileScreen-container {
  background-color: $primaryLightBgColor;
  width: 100%;
  height: 100vh;
  margin-top: 136px;
  margin-right: 48px;
}

.EditProfileScreen-mainContent {
  margin-left: 300px;
  width: 40%;
  min-width: 480px;

  @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
    margin-left: 240px;
    width: calc(100% - 240px - 120px)
  }

  @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
    margin-left: 240px;
    width: calc(100% - 240px - 48px)
  }
}

.EditProfileScreen-title {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
  padding-top: 18px;
}

.EditProfileScreen-form div {
  width: 100%;
}

.EditProfileScreen-sendProfileButton {
  width: 232px;
  height: 48px;
  background-color: $primaryBlue;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $neutralLightest;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 40px auto 48px;
}

.EditProfileScreen-sendProfileButton-Disabled {
  @include ctaBtn-Disable;
  margin: 40px auto 48px;
  width: 232px;
  height: 48px;
}

.EditProfileScreen-info {
  font-size: 14px;
  color: $primaryTextColor;
  line-height: 26px;
  margin-top: 18px;
}

.EditProfileScreen-mainContent hr {
  background-color: white;
  border: none;
  height: 1px;
  margin-bottom: 22px;
}

@media screen and (max-width: $mobile-large) {
  .EditProfileScreen-mainContent {
    margin-left: 16px;
    width: calc(100% - 32px);
    min-width: auto;
  }

  .EditProfileScreen-container {
    margin-top: 104px;
  }

  .EditProfileScreen-sendProfileButton {
    width: 100%;
    height: 56px;
    
  }
  .EditProfileScreen-info {
    line-height: 1.7;
  }

}
