@import '../../constants';

.FeedbackContainer-container {
  width: calc(50%);
}

.FeedbackContainer-header {
  display: inline-block;
  width: calc(100% - 48px);
}

.FeedbackContainer-collapsable {
  overflow: hidden;
  transition: max-height 0.3s, margin-top 0.3s;
  background-color: $neutralLightest;
  border-radius: 10px;
}

.FeedbackContainer-chevron-collapsable {
  transition: 300ms ease all;
  margin-top: auto;
  margin-bottom: auto;
}

.FeedbackContainer-collapsable.FeedbackContainer-collapsed {
  max-height: 0;
  margin-top: 0;
}

.FeedbackContainer-chevron-collapsable.FeedbackContainer-chevron-collapsed {
  transform: rotate(180deg);
}

.__react_component_tooltip {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.16);
  background: white;
  border-radius: 7px !important;
  opacity: 1 !important;
  padding: 18px 13px !important;
}

.__react_component_tooltip span {
  color: $primaryTextColor;
  font-size: 13px;
  font-weight: normal;
  text-align: left !important;
}

.FeedbackContainer-title {
  margin-bottom: 5px;
}

.FeedbackContainer-title-span {
  font-size: 24px;
  color: $neutralDark;
  font-weight: 600;
  margin-right: 8px;
}

.place-right::after {
  border-top: 12px solid transparent !important;
  border-bottom: 12px solid transparent !important;
  left: -12px !important;
  border-right-width: 12px !important;
}

.FeedbackContainer-description {
  color: $primaryTextColor;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  width: 98%;
}

.FeedbackContainer-addButton {
  display: inline-block;
  float: right;
  width: 48px;
  height: 48px;
  box-shadow: -3px -3px 3px 0 rgba(255, 255, 255, 1), 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
}

.FeedbackContainer-feedback-versionInfo {
  margin-top: 16px;
  background-color: $neutralLightest;
  display: flex;
  padding: 21px 24px 22px;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  color: $primaryTextColor;
  font-weight: bold;
}

.FeedbackContainer-feedback-versionInfo.FeedbackContainer-versionInfo-collapsed {
  border-radius: 10px;
}

.FeedbackContainer-feedback-versionData {
  display: inline-flex;
  align-items: center;
}

.FeedbackContainer-version {
  margin-right: 8px;
}

.FeedbackContainer-seeAll {
  text-align: center;
  font-size: 13px;
  color: $primaryBlue;
  font-weight: 600;
  padding: 24px;
  background-color: $neutralLightest;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.FeedbackContainer-noReview {
  padding: 24px;
  color: $primaryTextColor;
  font-size: 14px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.FeedbackContainer-mainContent {
  background-color: white;
  border-radius: 10px 10px 10px 10px;
}

#FeedbackContainer-stars-container {
  display: contents;
}

@media screen and (max-width: $mobile-large) {
  .FeedbackContainer-container {
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 56px;
  }

}


@media screen and (min-width: $mobile-large) and (max-width: 855px) {
  .FeedbackContainer-container {
    width: 92%;
    margin-right: 48px;
    margin-left: 0px;
    margin-top: 56px;
  }
}

@media screen and (min-width: 856px) and (max-width: $desktop-small) {
  .FeedbackContainer-container {
    margin-top: 0px;
    margin-left: 0px;
    width: calc(50% - 32px);
    margin-right: 32px
  }

  .FeedbackContainer-description {
    width: 95%;
  }
  
}




