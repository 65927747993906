@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import "constants";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;
}


a {
  color: inherit;
  text-decoration: none;
}


body {
  left: 0;
  top: 0;
  width: 100%;
  font-family: $font-stack;
  background-color: $primaryLightBgColor;
}

body .modal-container {
  overflow: hidden;
  position: fixed;
  z-index: 2;
}

input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
  background: transparent;
  border: none;
  outline: none;
}

textarea { resize: none; overflow: auto;}

input::placeholder {
  color: $neutralMidDark;
}

input:focus::placeholder {
  color: transparent;
}


:focus {
  background-color: transparent;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: url("assets/images/flat-chevron.svg") no-repeat;
  background-size: 18px 11px;
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 30px;
  padding: 1rem;
}

