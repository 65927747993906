@import '../../../constants';
@import '../../../buttons';

.LoginScreen-container {
  background: linear-gradient(
    to left,
    white 0%,
    white 40%,
    white 40%,
    $primaryLightBgColor 40%,
    $primaryLightBgColor 100%
  );
  min-height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  height: auto;

  @media screen and (max-width: $mobile-large) {
    background: $primaryLightBgColor;
    height: auto;
    padding: 32px 0 0;
    flex-flow: column wrap;
    justify-content: flex-start;
   }
 
   @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
     flex-flow: column wrap;
     background: $primaryLightBgColor;
     justify-content: flex-start;
    }
 

  .LoginScreen-freedrumLogoContainer {
    order: 1;
    width: 80%;
    height: 40px;
    text-align: left;
    padding-top: 32px;

    @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      width: 100%;
      order: 1;
      text-align: center;
      margin-bottom: 24px;
      margin-top: 32px;
    }

    @media screen and (max-width: $mobile-large) {
      margin-top: 0px;
      text-align: center;
      margin-bottom: 0px;
      padding-top: 0px;
      width: 100%;
    
    }
  }

  .LoginScreen-container__section {
    height: calc(100% - 80px);
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    order: 2;
  }

  .LoginScreen-phonesPictureContainer {
    width: 50%;
    height: auto;
    order: 3;
    margin: 0;

    .LoginScreen-phonesPictureContainer__image {
      width: 536px;
      height: auto;
    }

    @media screen and (max-width: $mobile-large) {
      width: 80%;
      order: 2;

      .LoginScreen-phonesPictureContainer__image {
        width: 100%; 
      }
    }

    @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      width: 40%;
      order: 2;
      margin: 0;

      .LoginScreen-phonesPictureContainer__image {
        width: 100%; 
      }
    }
  }

  .LoginScreen-phonesPictureContainer.email {
    @media screen and (max-width: $mobile-large) {
      display: none;
    }
  }

}

.LoginScreen-formContainer {
  width: 50%;
  display: flex;
  flex-flow: column wrap;
  order: 2;
  padding-left: 10%;
  height: calc(100% - 40px);
  justify-content: center;

  @media screen and (max-width: $mobile-large) {
      width: 100%;
      order: 3;
      padding: 32px 16px 48px 16px;
  }

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 100%;
    order: 3;
    padding: 32px 16px 48px 16px;
    align-items: center;
  }

  .RegisterScreen-formContainer-backButton{
    width:100%;
      height: 48px;
      margin-bottom: 24px;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: $mobile-large) {
        justify-content: center;
      }

      @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
        width: 450px;
        justify-content: center;
      }

    .RegisterScreen-formContainer-backButton-image {
      width: 48px;
      height: 48px;
      border: none;
      background-size: cover;
      border-radius: 50px;
      background-color: #d8d8d8;
      /*box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);*/
      cursor: pointer;
      font-size:30px;

      .RegisterScreen-formContainer-backButton-image-icon {
        font-size: 30px;
        vertical-align: middle;
        position: relative;
        left:-1px;
        top:-2px;
      }
    }

    @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
      width: 450px;
      
    }

  }
  
}

.LoginScreen-formContainer-title {
  font-size: 24px;
  font-family: $font-stack;
  font-weight: 600;
  margin-bottom: 24px;
  color: $neutralDark;
  width: 70%;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
  }

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 100%;
    text-align: center;
  }
}

.LoginScreen-formContainer-title.one {
  text-align: center;
  margin-bottom: 48px;
}

.LoginScreen-formContainer-error {

  width: 450px;
  height: auto;
  font-family: $font-stack;
  font-weight: 600;
  color: $primaryDarkBgColor;
  font-size: 11px;
  background-color: rgba(255, 23, 68, 0.5);
  padding: 16px 16px;
  display: inline-flex;
  align-items: center;


}


.LoginScreen-form {
  width: 80%;
  height: auto;
  margin-bottom: 16px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }

  @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
    width: 450px;
  }

}

.LoginScreen-form.one {
  align-items: center;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }

  @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
    width: 450px;
  }

}

.LoginScreen-form-container {
  width: 100%;
  text-align: center;
}

.LoginScreen-textBelowInputs {
  margin-top: 8px;
  margin-bottom: 48px;
  width: 450px;
  line-height: 16px;
  font-weight: 600;
  font-size: 11px;
  color: $primaryBlue;
  text-align: left;
  border: none;
  font-family: $font-stack;
  cursor: pointer;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }

}

.LoginScreen-registerButton {
  @include ctaBtn;
  margin: auto;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }
}

.LoginScreen-registerButton-Disable{
  @include ctaBtn-Disable;
  

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.LoginScreen-registerButton:hover {
  background: #E6163F;
}

.LoginScreen-separator {
  width: 100%;
  max-width: 350px;
  height: auto;
  position: relative;
  margin: 32px 0;

  @media screen and (max-width: $mobile-large) {
      width:100%;
      padding: 0;
  }
  
  .LoginScreen-separatorText {
      font-family: $font-stack;
      font-size: 13px;
      color: $primaryTextColor;
      font-weight: 600;
      position: relative;
      text-align: center;
  }

  .LoginScreen-separatorText::before {
    @include separatorIcons("assets/images/drumstick-separator-left.svg", 5px);
    left: 0;

      @media only screen and (min-width: 320px) and (max-width: 640px) {
              width: 125px;  
          }

      @media only screen and (min-width: 360px) and (max-width: 640px) {
              width: 145px;
          }
  }

  .LoginScreen-separatorText::after {
    @include separatorIcons("assets/images/drumstick-separator-right.svg", 5px);
    right: 0;

      @media only screen and (min-width: 320px) and (max-width: 640px) {
          width: 125px;  
          background-position: right;
      }

  @media only screen and (min-width: 360px) and (max-width: 640px) {
          width: 145px;
          background-position: right;
      }
    
  }
}

.LoginScreen-termsAndConditions {
  font-family: $font-stack;
  color: $primaryTextColor;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  font-size: 11px;

  @media screen and (max-width: $mobile-large) {
    width:100%;
    padding: 0;
  }
}

.LoginScreen-askRegister {
  width: 80%;
  text-align: center;
  font-family: $font-stack;
  font-size: 13px;
  margin-top: 32px;
  color: $primaryTextColor;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }

  .LoginScreen-askRegisterButton {
    font-weight: 600;
    color: $primaryBlue;
    cursor: pointer;
  }

}
