@import "../../../constants";
@import "../../../buttons";

.register__contentBox {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    background-image: url("../../../assets/images/background-desktop.svg");
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: $tablet) {
        height: auto;
        background-image: none;
    }

    .RegisterScreenNamm-Container__InnerBox{

        position: relative;
        height: auto;
        width: calc(100vw - 32px);
        max-width: 700px;
        padding: 56px 32px;
        background-color: $neutralLightest;
        margin: 32px 16px;
        border-radius: 20px;


        @media screen and (max-width: 320px) {
            padding: 56px 16px;
        }

        .RegisterScreenNamm-Container__InnerBox-TopArea__header{
            width: 100%;
            height: auto;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;

            @media screen and (max-width: 320px) {
                flex-flow: column wrap;
                justify-content: center;
                align-items: center;

                .RegisterScreenNamm-Container__InnerBox-TopArea__header-NammLogoContainer{
                    margin-top: 16px;
                }
            }
        }

        .RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer-box-title{
            font-size: 20px;
            color: $neutralDark;
            text-align: center;
            font-weight: 600;
            margin: 0px auto 16px;
            line-height: 1.5;
            width: 80%;

            @media screen and (max-width: $mobile-large) {
              width: 100%;
            }
            
        }

        @media screen and (max-width: $tablet) {
            
            .RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer{
                width: 100%;
                height: auto;

                .RegisterScreenNamm-Container__InnerBox-TopArea__FormContainer-box{
                    width: 100%;
                    height: auto;

                    
                }
            }


        }

        .register__containerBox-formBox{
            width: 80%;
            display: flex;
            align-content: center;
            justify-content: center;
            margin: 0 auto;

            @media screen and (max-width: $mobile-large) {
              width: 100%;
              padding: 0px;
            }
        }

        .Login__containerBox-formBox {
          width: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
        }

        .RegisterScreen-form {
            width: 100%;
            height: auto;
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            justify-content: center;
        
            @media screen and (max-width: $mobile-large) {
              width: 100%;
            }
        
            @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
              width: 100%;
            }
        
        }

        .RegisterScreen-textBelowInputs-container {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            margin-top: 8px;
        
            @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
              width: 450px;
            }
          }

        
        .RegisterScreen-textBelowInputs {
            color: $primaryTextColor;
            width: 90%;
            line-height: 16px;
            font-size: 11px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;

            @media screen and (max-width: $mobile-large) {
            width: 90%;
            }

            @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
            width: 90%;
            }
        }

        .RegisterScreen-textBelowInputs.password {
            width: 100%;
            margin-top: 0px;
            margin-left: 0px;
        }

        .RegisterScreen-form-container {
            width: 100%;
            text-align: center;
        }

        .RegisterScreen-termsAndConditions {
            font-family: $font-stack;
            color: $primaryTextColor;
            width: 100%;
            line-height: 1.5;
            margin-top: 24px;
            margin-bottom: 32px;
            font-size: 13px;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            position: relative;
        
            @media screen and (max-width: $mobile-large) {
              width: 100%;
            }
        
            .RegisterScreen-termsAndConditions__button {
              border: none;
              font-family: $font-stack;
              color: $primaryTextColor;
              font-size: 13px;
              text-align: left;
              line-height: 1.5;
        
            }
        
            .RegisterScreen-termsAndConditions__label {
              position: relative;
              cursor: pointer;
              padding-left: 0px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              width: 100%;
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              align-items: center;
              position: relative;
            }
        
            .RegisterScreen-termsAndConditions__label input[type="checkbox"] {
              clip: rect(0 0 0 0);
              padding: 0;
              width: 1px;
              height: 1px;
              overflow: hidden;
              position: absolute;
              top: -2px;
              left: -42px;
              border: 0;
            }
        
            .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom {
              display: block;
              background-color: #f8f8f8;
              box-shadow: inset -2px -2px 5px rgba(white, 1),
              inset 2px 2px 5px rgba(black, 0.1);
              border-radius: 3px;
              
            }

            .checkbox-custom:after {
              content: "";
              position: absolute;
              display: none;
            }
        
            /* Show the checkmark when checked */
            .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom:after {
              display: block;
            }
        
            .RegisterScreen-termsAndConditions__label .checkbox-custom:after {
              position: relative;
              margin: auto;
              top: 4px;
              width: 5px;
              height: 10px;
              border: solid $primaryBlue;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
        
        
            .RegisterScreen-termsAndConditions__checkbox {
              position: relative;
              top: -9px;
              margin-right: 10px;
              border: none;
              width: 20px;
            }
        
            .checkbox-custom {
              width: 25px;
              height: 25px;
              display: block;
              background-color: $neutralLightest;
              box-shadow: -2px -2px 5px rgba(white, 1),
              2px 2px 5px rgba(black, 0.2);
              border-radius: 3px;
              margin-right: 16px;
              background-size: cover;
              border: 1px solid #ffffff;
            }

            input:focus + .checkbox-custom {
              border: 1px solid #696969;
            }
        
            .RegisterScreen-termsAndConditions__label > p {
              width: calc(100% - 42px);
            }

            .terms_checkbox_extra{
                margin: 0px 0 16px 40px;

                @media screen and (max-width: $tablet) {
                    margin: 16px 0 0 40px;
                  }
            }
        
        
            .RegisterScreen-termsAndConditions__textLink {
              color: $primaryBlue;
            }

            .RegisterScreen-termsAndConditions__textLink:focus-within {
              text-decoration: underline;
            }
        
          }

          .RegisterScreen-infoIcon {
            margin-right: 6px;
            position: relative;
            margin-top: auto;
            margin-bottom: auto;
            width: 20px;
            font-size: 16px;
            color:$primaryTextColor;
          }
        
          .RegisterScreen-wrongPasswordIcon {
            margin-right: 6px;
            color: $primaryTextColor;
            position: relative;
            font-size: 16px;
            margin-top: auto;
            margin-bottom: auto;
            
          }

          .RegisterScreen-registerButton{
              color: $neutralLightest;
              background-color: $primaryBlue;
              height: 48px;
              width: 216px;
              border: none;
              border-radius: 30px;
              box-shadow: -2px -2px 5px rgba(white, 1),
              2px 2px 5px rgba(black, 0.1);
              position: absolute;
              bottom: -24px;
              font-family: $font-stack;
              font-weight: 600;
              left: 50%;
              transform: translate(-50%, 0);

              @media screen and (max-width: $tablet) {
                position: absolute;
                bottom: -52px;
                margin: 24px 0px;
                height: 56px;
              }
          }

          .RegisterScreen-registerButton:focus-within{
            background-color: #0068AF;
          }

          .RegisterScreen-registerButton:hover{
              cursor: pointer;
              background-color: #0068AF;
          }

    }

    .RegisterScreenNamm-Container__InnerBox__TextBox{
        width: 100%;
        margin: 64px 0;
        text-align: center;
        color: $neutralDark;

        .RegisterScreenNamm-Container__InnerBox__TextBox_Title{
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 24px;
        }

        .RegisterScreenNamm-Container__InnerBox__TextBox_Copy{
            font-size: 16px;
            line-height: 1.5;
        }

        .RegisterScreenNamm-Container__InnerBox__TextBox_Numbers {
            margin: 24px 0;
            font-size: 56px;
            font-weight: 600;
        }
    }

    .RegisterScreenNamm-Container__InnerBox__LinkBox{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-around;

        @media screen and (max-width: 320px) {
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
        }


        .SubscriptionScreen-mainContent__DownloadLink-iOS {
            width: calc(25% - 16px);
            margin-right: 16px;
            margin-bottom: 0px;

            @media screen and (max-width: $mobile-large) {
                width: calc(50% - 8px);
                margin-right: 8px;
                margin-bottom: 16px;
            }

            @media screen and (max-width: 320px) {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 16px;
            }
        }

        .SubscriptionScreen-mainContent__DownloadLink-Google {
            @media screen and (max-width: $mobile-large) {
                width: calc(50% - 8px);
                margin-left: 8px;
                margin-bottom: 16px;
                margin-right: 0px;
            }

            @media screen and (max-width: 320px) {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 16px;
                margin-left: 0px;
            }

            width: calc(25% - 16px);
            margin-right: 16px;
            margin-bottom: 0px;
        }

        .SubscriptionScreen-mainContent__DownloadLink-MacOSButton {
            @media screen and (max-width: $mobile-large) {
                width: calc(50% - 8px);
                margin-right: 8px;
                margin-bottom: 0px;
            }

            @media screen and (max-width: 320px) {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 16px;
            }

            width: calc(25% - 16px);
            margin-right: 16px;
            margin-bottom: 0px;
        }

        .SubscriptionScreen-mainContent__DownloadLink-WindowsButton {
            @media screen and (max-width: $mobile-large) {
                width: calc(50% - 8px);
                margin-left: 8px;
                margin-bottom: 0px;
            }

            @media screen and (max-width: 320px) {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 16px;
                margin-left: 0px;
            }

            width: calc(25% - 0px);
            margin-left: 0px;
            margin-bottom: 0px;
        }
    }

    .RegisterPage__logIn {
      margin: 24px 0;
      color: $primaryTextColor;
    }

    .RegisterPage__logIn-span {
      font-weight: 700;
      color: $primaryBlue;
    }

    .RegisterPage__logIn-span:hover {
      color: #0068AF;
    }

    .footer-text{
        width: 100%;
        height: auto;
        font-size: 13px;
        color: $primaryTextColor;
        margin: 32px 0;
        text-align: center;
        line-height: 1.5;

        @media screen and (max-width: 320px) {
            width: 80%;
        }
    }

    .footer-text.success{
        margin: 0px 0px 32px 0px;
    }
}