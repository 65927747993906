@import "../../../../constants";
@import "../../../../buttons";

.AddFeedbackScreen-container {
  background-color: $primaryLightBgColor;
  width: 100%;
  margin-top: 136px;
}

.AddFeedbackScreen-mainContent {
  margin-left: 300px;
  padding-top: 18px;

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin-left: 240px;
  }
}

.AddFeedbackScreen-mainContent .ErrorBox-container {
  margin-top: 16px;
}

.AddFeedbackScreen-title {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
}

.AddFeedbackScreen-smallText {
  color: $primaryTextColor;
  margin-top: 8px;
  line-height: 16px;
  display: inline-flex;
}

.AddFeedbackScreen-smallText span {
  padding-right: 4px;
  padding-top: 2px;
}

.AddFeedbackScreen-form {
  width: 50%;
}

.AddFeedbackScreen-form .FormInput-nameField {
  width: 100%;
}

.AddFeedbackScreen-rating-container {
  color: $primaryTextColor;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.AddFeedbackScreen-ratingText {
  font-size: 14px;
  font-weight: 600;
}

.AddFeedbackScreen-stars-container {
  margin-top: 14px;
}

.AddFeedbackScreen-textArea-container .FormInput-nameField {
  height: 110px;
}

.AddFeedbackScreen-save {
  @include ctaBtn;
  margin-top: 32px;
  margin-bottom: 48px;
}

.AddFeedbackScreen-feedbackSend-container {
  width: 50%;
}

.AddFeedbackScreen-feedbackSend-text {
  color: $primaryTextColor;
  font-size: 16px;
  line-height: 26px;
  margin-top: 42px;
}

.AddFeedbackScreen-backToFeedback {
  width: 320px;
  height: 56px;
  background-color: $primaryLightBgColor;
  border-radius: 28px;
  border: none;
  font-size: 14px;
  font-family: $font-stack;
  font-weight: bold;
  color: $fdRed;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 40px 0px;
  display: block;
}

@media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
  .AddFeedbackScreen-form {
    width: calc(100% - 16px);
  }

  .AddFeedbackScreen-feedbackSend-container{
    width: calc(100% - 16px);
  }

  .AddFeedbackScreen-save {
    width: 100%;
  }

  .AddFeedbackScreen-feedbackSend-text {
    font-size: 14px;
    line-height: 1.7;
  }
}

@media screen and (max-width: $mobile-large) {
  .AddFeedbackScreen-mainContent {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
  }

  .AddFeedbackScreen-container {
    margin-top: 104px;
  }

  .AddFeedbackScreen-backToFeedback {
    width: 100%;
  }
}
