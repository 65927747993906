@import "../../constants";


.SuccessBox-container {
    width: 100%;
    height: auto;
    font-family: $font-stack;
    font-weight: 600;
    color: #00A21F;
    font-size: 11px;
    background-color: #E6F8E5;
    padding: 16px 16px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #00A21F;
    position: relative;
    margin-bottom: 16px;
    max-width: 500px;

    @media screen and (max-width: $mobile-large) {
        width: 95%;
        margin: 0px auto 16px;
      }
  }


  .SuccessBox-container p {
      font-weight: 500;
      margin-left: 16px;
      line-height: 1.5;
  }


  .SuccessBox-container span {
      font-weight: bold;
  }

  .SuccessBox-container__closeBtn {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
    padding: 8px 8px 5px 8px;
    background-color:#E6F8E5;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
  }

  .SuccessBox-container__closeBtn:hover {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
    padding: 8px 8px 5px 8px;
    background-color: rgba(white,0.7);
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
  }