@import "../../constants";

.Header-container {
  background-color: $neutralLightest;
  align-items: center;
  color: $primaryTextColor;
  justify-content: space-between;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  position: fixed;
  top: 0;
  height: 80px;
  width: 100%;
  z-index: 2;
  border-bottom: 2px solid #FFFFFF;
}

.Header-name {
  margin-left: 96px;
}

.Header-logout {
  margin-right: 106px;
  border: none;
  font-family: $font-stack;
  color: $primaryTextColor;
  background-color: transparent;
  font-weight: 600;
  cursor: pointer;
  padding: 16px 24px;
}

.Header-logout:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
}

.Header-burgerMenu {
  cursor: pointer;
  display: none;
}
.Header-freedrumLogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: $mobile-large) {
  .Header-name {
    display: none;
  }

  .Header-logout {
    display: none;
  }

  .Header-container {
    justify-content: center;
    height: 88px;
    z-index: 2;
  }

  .Header-burgerMenu {
    margin-left: 24px;
    display: inline;
    position: absolute;
    left: 0;
  }

  .Header-container {
    background: $primaryLightBgColor;
  }
}

