@import "../../constants";

.Instruction-container {
    display: flex;
    margin-top: 20px;
    margin-left: 10%;
    margin-right: 12%;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    @media only screen and (max-width: $mobile-large) {
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        margin-left: 24px;
        margin-right: 24px;
    }
}

.Instruction-number {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $primaryTextColor;
    margin-right: 26px;
    padding: 0;
    display: inherit;

    @media only screen and (max-width: $mobile-large) {
        margin-right: 0px;
        position: absolute;
        top: -18px;
    }
}

.Instruction-number p {
    width: 36px;
    line-height: 36px;
    color: $neutralLightest;
    text-align: center;
    font-weight: 600;
}

.Instruction-info {
    background-color: $neutralLightest;
    width: calc(100% - 62px);
    border-radius: 10px;

    @media only screen and (max-width: $mobile-large) {
        width: 100%;
        margin-bottom: 24px;
    }
}

.Instruction-info p {
    margin: 24px;
    font-size: 14px;
    color: $primaryTextColor;
    line-height: 26px;

    @media only screen and (max-width: $mobile-large) {
        margin-top: 32px;
        line-height: 1.7;
    }
}

.Instruction-info-red {
    color: $primaryBlue;
    font-weight: 600;
}

.Instruction-info-semiBold {
    font-weight: 600;
}
