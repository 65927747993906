@import "../../../constants";
@import "../../../buttons";

@mixin ContainerStyle($height, $background) {
  width: 100vw;
  height: $height;
  background: $background;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

@mixin  ContainBoxStyle($justifyContent, $paddingValue, $backgroundColor, $flexFlow) {
  max-width: 1680px;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-flow: $flexFlow;
  align-items: center;
  justify-content: $justifyContent;
  background-color: $backgroundColor;
  padding: $paddingValue;
}


.RegisterScreenUpdate-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  background-color: $primaryLightBgColor;

   /* ==== FORM SECTION === */

  .RegisterScreenUpdate-TopArea {
      width: 100vw;
      height: 100vh;
      background: rgb(105,105,105);
      background: linear-gradient(90deg, rgba(105,105,105,0.39401698179271705) 0%, rgba(239,239,239,1) 100%);
      position: relative;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $mobile-large) {
        align-items: flex-start;
        height: auto;
        width: 100vw;
        overflow: hidden;
      }

      .RegisterScreenUpdate-TopArea__heroimage {
          width: 100vw;
          height: 100vh;
          background-image: url("../../../assets/images/hero-image-screens.webp");

          background-position: center;
          background-repeat: no-repeat;

          @media screen and (max-width: $mobile-large) {
            height: 280px;
            background-size: cover;
            transform: scale(2);
          }
      }

      .RegisterScreenUpdate-TopArea__header{
            max-width: 1680px;
            height: 80px;
            width: 100vw;
            position: absolute;
            top: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: center;

            @media screen and (max-width: $mobile-large) {
              width: 100%;
              padding: 16px;
            }

            @media screen and (min-width: $mobile-large) and (max-width: $desktop-Xlarge) {
                width: 100%;
                padding: 0px 80px;
            }

            .RegisterScreenUpdate-TopArea__header-freedrumLogoContainer {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-start;
                width: 50%;
                height: 100%;
            
            }

            .RegisterScreenUpdate-TopArea__header-logIn{
                width: 50%;
                height: 100%;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-end;

                .RegisterScreenUpdate-TopArea__header-logIn-Button{
                    padding: 8px 32px;
                    background-color: $fdGreen;
                    color: $neutralLightest;
                    border-radius: 20px;
                    border: none;
                    font-family: $font-stack;
                    font-weight: 600;
                }

                .RegisterScreenUpdate-TopArea__header-logIn-Button:hover{
                    padding: 8px 32px;
                    background-color: #2e8400;
                    color: $neutralLightest;
                    cursor: pointer;
                }
            }
      }

      .RegisterScreenUpdate-TopArea__FormContainer{
        width:100%;
        height: calc(100% - 280px);
        max-width: 1680px;
          position: absolute;
          display: flex;
          flex-flow: column wrap;
          align-items: center;
          justify-content: center;


        @media screen and (min-width: $mobile-large) and (max-width: $desktop-Xlarge) {
            width: 100%;
            padding: 0px 80px;
        }

        @media screen and (max-width: $mobile-large) {
          align-items: center;
          position: relative;
        }

        .RegisterScreenUpdate-TopArea__FormContainer-box{
            width: 488px;
            height: auto;
            border-radius: 20px;
            background-color: $neutralLightest;
            -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
            -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
            box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            padding: 48px;
            position: relative;

            @media screen and (max-width: $mobile-large) {
              width: 100%;
              padding: 32px;
              border-radius: 20px 20px 0 0;
            }

            .register__containerBox-formBox{
                width: 100%;

                @media screen and (max-width: $mobile-large) {
                  width: 100%;
                  padding: 0px;
                }
            }

            .RegisterScreen-form {
                width: 100%;
                height: auto;
                display: flex;
                flex-flow: column wrap;
                align-items: flex-start;
                justify-content: center;
            
                @media screen and (max-width: $mobile-large) {
                  width: 100%;
                }
            
                @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
                  width: 100%;
                }
            
            }

            .RegisterScreenUpdate-TopArea__FormContainer-box-title{
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 16px;
                color: #212121;
            }

            .RegisterScreenUpdate-TopArea__FormContainer-box-SmallTitle{
                font-size: 16px;
                font-weight: 500;
                width: 100%;
                margin-bottom: 16px;
                color: #212121;
            }

            .RegisterScreen-textBelowInputs-container {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-items: center;
                margin-top: 4px;
            
                @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                  width: 450px;
                }
              }

            
            .RegisterScreen-textBelowInputs {
                color: $primaryTextColor;
                width: 90%;
                line-height: 16px;
                font-size: 11px;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-start;

                @media screen and (max-width: $mobile-large) {
                width: 90%;
                }

                @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                width: 90%;
                }
            }

            .RegisterScreen-textBelowInputs.password {
                width: 100%;
                margin-top: 0px;
                margin-left: 0px;
            }

            .RegisterScreen-form-container {
                width: 100%;
                text-align: center;
            }
        }

        .RegisterScreen-termsAndConditions {
            font-family: $font-stack;
            color: $primaryTextColor;
            width: 100%;
            line-height: 1.5;
            margin-top: 24px;
            margin-bottom: 16px;
            font-size: 13px;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            position: relative;
        
            @media screen and (max-width: $mobile-large) {
              width: 100%;
            }
        
            .RegisterScreen-termsAndConditions__button {
              border: none;
              font-family: $font-stack;
              color: $primaryTextColor;
              font-size: 13px;
              text-align: left;
              line-height: 1.5;
        
            }
        
            .RegisterScreen-termsAndConditions__label {
              position: relative;
              cursor: pointer;
              padding-left: 0px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              width: 100%;
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              align-items: center;
              position: relative;
            }
        
            .RegisterScreen-termsAndConditions__label input[type="checkbox"] {
              clip: rect(0 0 0 0);
              padding: 0;
              width: 1px;
              height: 1px;
              overflow: hidden;
              position: absolute;
              top: -2px;
              left: -42px;
              border: 0;
            }
        
            .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom {
              display: block;
              background-color: $primaryLightBgColor;
              box-shadow: inset -2px -2px 5px rgba(white, 1),
              inset 2px 2px 5px rgba(black, 0.1);
              border-radius: 3px;
              
            }

            .checkbox-custom:after {
              content: "";
              position: absolute;
              display: none;
            }
        
            /* Show the checkmark when checked */
            .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom:after {
              display: block;
            }
        
            .RegisterScreen-termsAndConditions__label .checkbox-custom:after {
              position: relative;
              margin: auto;
              top: 4px;
              width: 5px;
              height: 10px;
              border: solid $primaryBlue;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
        
        
            .RegisterScreen-termsAndConditions__checkbox {
              position: relative;
              top: -9px;
              margin-right: 10px;
              border: none;
              width: 20px;
            }
        
            .checkbox-custom {
              width: 25px;
              height: 25px;
              display: block;
              background-color: $neutralLightest;
              box-shadow: -2px -2px 5px rgba(white, 1),
              2px 2px 5px rgba(black, 0.2);
              border-radius: 3px;
              margin-right: 16px;
              background-size: cover;
              border: 1px solid #ffffff;
            }

            input:focus + .checkbox-custom {
              border: 1px solid #696969;
            }
        
            .RegisterScreen-termsAndConditions__label > p {
              width: 85%;
            }
        
        
            .RegisterScreen-termsAndConditions__textLink {
              color: $primaryBlue;
            }

            .RegisterScreen-termsAndConditions__textLink:focus-within {
              text-decoration: underline;
            }
        
          }

          .RegisterScreen-infoIcon {
            margin-right: 6px;
            position: relative;
            margin-top: auto;
            margin-bottom: auto;
            width: 20px;
            font-size: 16px;
            color:$primaryTextColor;
          }
        
          .RegisterScreen-wrongPasswordIcon {
            margin-right: 6px;
            color: $primaryTextColor;
            position: relative;
            font-size: 16px;
            margin-top: auto;
            margin-bottom: auto;
            
          }

          .RegisterScreen-registerButton{
              color: $neutralLightest;
              background-color: $fdGreen;
              height: 48px;
              width: 216px;
              border: none;
              border-radius: 30px;
              box-shadow: -2px -2px 5px rgba(white, 1),
              2px 2px 5px rgba(black, 0.1);
              position: absolute;
              bottom: -24px;
              font-family: $font-stack;
              font-weight: 600;
              left: 50%;
              transform: translate(-50%, 0);

              @media screen and (max-width: $mobile-large) {
                position: relative;
                bottom: 0px;
                margin: 24px 0px;
                height: 56px;
              }
          }

          .RegisterScreen-registerButton:focus-within{
            background-color: #2e8400;
          }

          .RegisterScreen-registerButton:hover{
              cursor: pointer;
              background-color: #2e8400;
          }
      }

      
  }

   /* ==== PRICING SECTION === */
  
  .RegisterScreenUpdate-PricingContainer {
        @include ContainerStyle($height:320px, $background:#FFFFFF);

        @media screen and (max-width: $mobile-large) {
          flex-flow: column wrap;
          height: auto;
        }

        .RegisterScreenUpdate-PricingContainer__ContentBox {
          @include ContainBoxStyle(space-between, 0 80px, #FFFFFF, row wrap);
          
            @media screen and (max-width: $mobile-large) {
              width: 100%;
              padding: 48px 32px 0px;
            }

            .RegisterScreenUpdate-PricingContainer__ContentBox-Price{
              width: 45%;
              height: 192px;
              border-radius: 20px;
              border: 2px#C9C9C9;
              border-style: solid;

              @media screen and (max-width: $mobile-large) {
                width: 100%;
                margin-bottom: 48px;
              }

                a {
                    width: 100%;
                    height: 192px;
                    flex-flow: column wrap;
                      display: flex;
                      flex-flow: column wrap;
                      align-items: center;
                      justify-content: center;
                      position: relative;
                      text-align: center;
                }

                .striketrough {
                text-decoration: line-through;
                }

              .RegisterScreenUpdate-PricingContainer__ContentBox-Price__PromoBox{
                width: auto;
                padding: 8px 48px 8px 48px;
                border: 2px solid $primaryBlue;
                border-radius: 0px 0px 20px 20px;
                background-color: $primaryBlue;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $neutralLightest;
                font-size: 14px;
                font-weight: 600;
                position: absolute;
                top: -2px;

                
              }

              .RegisterScreenUpdate-PricingContainer__ContentBox-Price__Title{
                font-size: 30px;
                color:$primaryBlue;
                font-weight: 600;
                margin-bottom: 8px;
              }

              .RegisterScreenUpdate-PricingContainer__ContentBox-Price__TagBody{
                font-size: 16px;
                color:$primaryTextColor;
                font-weight: 400;
              }
            }

            
        }
    }

     /* ==== SUBSCRIPTION FEATURES SECTION === */


    .RegisterScreenUpdate-SubcriptionContainer{

      @include ContainerStyle($height:auto, $background:$primaryLightBgColor);

      .RegisterScreenUpdate-SubcriptionContainer__ContentBox{
        @include ContainBoxStyle(center, 100px 0px 62px 0px, $primaryLightBgColor, row wrap);

        @media screen and (max-width: $mobile-large) {
          padding: 64px 0px;
        }

        .RegisterScreenUpdate-SubcriptionContainer__ContentBox-Title{
            font-size: 30px;
            color:$primaryDarkBgColor;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 54px;
            text-align: center;
            padding: 0px 80px;

            @media screen and (max-width: $mobile-large) {
              padding: 0px 32px;
            }
        }

        .RegisterScreenUpdate-SubcriptionContainer__ContentBox-FeatureContainer{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-around;
            padding: 0px;

            @media screen and (max-width: $mobile-large) {
              width: 100vw;
              padding: 0px;
            }

            @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
              width: 100%;
            }


            .RegisterScreenUpdate-SubcriptionContainer__ContentBox-FeatureContainer__HeroImage{
              width: 100%;
              height: 100%;
              padding-left: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              order: 2;

              @media screen and (max-width: $mobile-large) {
                width: 100%;
                padding-left: 0px;
                margin-left: 0px;
                overflow: hidden;
                order: 1;
              }

              @media screen and (min-width: $mobile-large) and (max-width: 1250px) {
                width: 100%;
                padding-left: 0px;
                margin-left: 0px;
                overflow: auto;
                order: 2;
              }

              .RegisterScreenUpdate-SubcriptionContainer__ContentBox-FeatureContainer__HeroImage__image{
                width: 75%;
                margin:-16px auto 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                

                @media screen and (max-width: $mobile-large) {
                  width: 170%;
                }

                @media screen and (min-width: $mobile-large) and (max-width: 1250px) {
                  width: 100%;
                }
              }
            }

            .RegisterScreenUpdate-SubcriptionContainer__ContentBox-FeatureContainer__FeatureLists{
              width:100%;
              height: auto;
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              align-items: flex-start;
              margin: 46px 80px 0px 80px;
              padding-right: 0px;
              order: 1;

              @media screen and (max-width: $tablet) {
                width: 100%;
                padding-right: 0px;
                margin-left: 0px;
                margin-top:32px;
                margin-right: 0px;
                order: 2;
              }
              

              .RegisterScreenUpdate-SubcriptionContainer__ContentBox-FeatureContainer__FeatureLists__Content{
                width: 30%;
                margin-left: 0px;
                margin-bottom: 42px;

                @media screen and (max-width: $tablet){
                  width: 100%;
                  margin-left: 0px;
                  padding: 0px 32px;
                }

                .RegisterScreenUpdate-SubcriptionContainer__ContentBox-FeatureContainer__FeatureLists__Content-Title{
                    font-size: 20px;
                    color:$primaryBlue;
                    font-weight: 600;
                    margin-bottom: 8px;

                    
                }

                .RegisterScreenUpdate-SubcriptionContainer__ContentBox-FeatureContainer__FeatureLists__Content-BodyText{
                    line-height: 1.6;
                    color:$primaryTextColor;
                    font-size: 16px;

                   
                }
              }
            }
        }

      }
    }

    /* ==== ARTWORK SECTION === */

    .RegisterScreenUpdate-ArtworkContainer{
      @include ContainerStyle($height:860px, $background:$primaryLightBgColor);

      .RegisterScreenUpdate-ArtworkContainer__ContentBox{
        @include ContainBoxStyle(center, 0px, $primaryLightBgColor, row wrap);
        position: relative;
      
        .RegisterScreenUpdate-ArtworkContainer__ContentBox-ImageBox{
          width: 100%;
          height:100%;

          .RegisterScreenUpdate-ArtworkContainer__ContentBox-ImageBox__image{
            width: 100%;
            height:100%;
            background-image: url("../../../assets/images/hero-image-artworks.webp");
            background-position: center;
            background-size: cover;

            @media screen and (max-width: $mobile-large) {
              width: auto;
              height:100%;
            }
          }
        }
      }

      .RegisterScreenUpdate-ArtworkContainer__ContentBox-TextBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFFFFF;
        width: 60%;
        max-width: 970px;

        @media screen and (max-width: $mobile-large) {
          width: 90%;
        }

        .RegisterScreenUpdate-ArtworkContainer__ContentBox-TextBox__BodyText{
          font-size: 32px;
          font-weight: 600;
          line-height: 1.3;

          @media screen and (max-width: $mobile-large) {
            font-size: 24px;
          }
        }
      }
    }

     /* ==== FAQ SECTION === */

    .RegisterScreenUpdate-FAQContainer {
      @include ContainerStyle($height:auto, $background:$primaryLightBgColor);

      .RegisterScreenUpdate-FAQContainer__ContentBox{
        @include  ContainBoxStyle(center, 100px 80px, $primaryLightBgColor, column wrap);

        @media screen and (max-width: $desktop-small) {
          padding: 48px 32px;
        }

        .RegisterScreenUpdate-FAQContainer__ContentBox-Title{
            font-size: 30px;
            color:$primaryDarkBgColor;
            font-weight: 600;
            margin-bottom: 48px;
            text-align: center;

        }

        .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer{
          width: 60%;
          display: flex;
          flex-flow: column wrap;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid $neutralMidDark;
          padding: 16px 32px;

          @media screen and (max-width: $mobile-large) {
            padding: 0px;
            width: 100%;
          }

          @media screen and (min-width: $mobile-large) and (max-width: 1250px){
            width: 80%;
          }
          

          .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer-TitleAndButton{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0px;
            line-height: 1.5;

            @media screen and (max-width: $mobile-large) {
              padding: 24px 0px;
            }

            .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer-TitleAndButton__Title{
              width: 90%;
              text-align: left;
              font-size: 18px;
              font-weight: 600;

              @media screen and (max-width: $mobile-large) {
                width: 80%;
              }
            }

            .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer-TitleAndButton__Button{
              width: 40px;
              border: none;
              color: $primaryBlue;
              height:40px;
              border-radius: 50%;
              background-color: $primaryLightBgColor;
              padding-top: 3px;

              .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer-TitleAndButton__Button-Icon {
                animation-name: closeButton;
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
                animation-timing-function: ease-out; 
              }

              @keyframes openButton {
                from {transform: rotate(0deg)}
                to {transform: rotate(45deg);} 
              }

              @keyframes closeButton {
                from {transform: rotate(45deg)}
                to {transform: rotate(0deg);} 
              }


              .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer-TitleAndButton__Button-Icon.opened {
                animation-name: openButton;
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
                animation-timing-function: ease-out;

              }
              
            }

            .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer-TitleAndButton__Button:hover{
              cursor: pointer;
              background-color: #e2e2e2;
            }
          }

          @keyframes openText {
            from {transform: translate(0, -16px)}
            to {transform: translate(0, 0)} 
          }

          @keyframes openTextOpacity {
            0%    { opacity: 0; }
            100%  { opacity: 1; }
          }

          @keyframes openTextMobile {
            from {transform: translate(0, 0px)}
            to {transform: translate(0, 0)} 
          }

          .RegisterScreenUpdate-FAQContainer__ContentBox-FAQBoxContainer-BodyCopy{
            font-size: 16px;
            margin-bottom: 16px;
            line-height: 1.8;
            color: $primaryTextColor;
            padding-right: 56px;
            animation-name: openText, openTextOpacity;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-out;

            @media screen and (max-width: $mobile-large) {
              padding-right: 0px;
              margin-bottom: 32px;
              animation-name: openTextMobile;
              transform: translate(0, 0);
              font-size: 16px;
              transform: opacity(1);
            }

            .link_inline {
              font-size: 16px;
              font-weight: 600;
            }
          }

        }

        .RegisterScreenUpdate-FAQContainer__ContentBox__registerButton{
          color: $neutralLightest;
          background-color: $fdGreen;
          height: 48px;
          width: 216px;
          border: none;
          border-radius: 30px;
          box-shadow: -3px -3px 5px rgba(white, 1),
          3px 3px 5px rgba(black, 0.1);
          font-family: $font-stack;
          font-weight: 600;
          margin-top: 80px;

          @media screen and (max-width: $mobile-large) {
            margin-top: 42px;
            margin-bottom: 16px;
            height: 56px;
          }
        }

        .RegisterScreenUpdate-FAQContainer__ContentBox__registerButton:hover{
          cursor: pointer;
          background-color: #2e8400;
        }
      }
    }

    /* ==== DEVICE LISTS SECTION === */

    .RegisterScreenUpdate-DeviceListsContainer{
      @include ContainerStyle($height:auto, $background:$neutralLightest);

        .RegisterScreenUpdate-DeviceListsContainer__ContentBox{
          @include  ContainBoxStyle(center, 80px, $neutralLightest, row wrap);

          @media screen and (max-width: $mobile-large) {
            padding: 80px 16px;
          }

          .RegisterScreenUpdate-DeviceListsContainer__ContentBox-Title{
            font-size: 24px;
            color:$primaryDarkBgColor;
            font-weight: 600;
            margin-bottom: 24px;
            width: 100%;
            text-align: center;

            @media screen and (max-width: $desktop-small) {
              margin-bottom: 48px;
            }
          }

          .RegisterScreenUpdate-DeviceListsContainer__ContentBox-image{
            width: 100%;
            max-width: 920px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;

            .SubscriptionScreen-mainContent__DownloadLink-iOS{
              max-height: 70px;
              margin-right: 16px;
              width: 20%;
            }

            .SubscriptionScreen-mainContent__DownloadLink-iOS:hover{
              cursor: pointer;
            }

            .SubscriptionScreen-mainContent__DownloadLink-Google{
              max-height: 70px;
              margin-right: 16px;
              width: 20%;
            }

            .SubscriptionScreen-mainContent__DownloadLink-Google:hover{
              cursor: pointer;
            }

            .SubscriptionScreen-mainContent__DownloadLink-MacOSButton{
              max-height: 70px;
              margin-right: 16px;
              width: 20%;
            }

            .SubscriptionScreen-mainContent__DownloadLink-MacOSButton:hover{
              cursor: pointer;
            }

            .SubscriptionScreen-mainContent__DownloadLink-WindowsButton{
              max-height: 70px;
              width: 20%;
            }

            .SubscriptionScreen-mainContent__DownloadLink-WindowsButton:hover{
              cursor: pointer;
            }

            @media screen and (max-width: 650px) {
              width: 100%;
              flex-flow: column wrap;

              .SubscriptionScreen-mainContent__DownloadLink-iOS{
                margin-right: 0px;
                width: 224px;
                margin-bottom: 16px;
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-Google{
                margin-bottom: 16px;
                margin-right: 0px;
                width: 210px;
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-MacOSButton{
                width: 210px;
                margin-right: 0px;
                margin-bottom: 16px;
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-WindowsButton{
                width: 210px;
              }
            }

            @media screen and (min-width: 651px) and (max-width: 780px) {
              width:100%;

              .SubscriptionScreen-mainContent__DownloadLink-iOS{
                margin-right: 16px;
                max-height: 70px;
                margin-bottom: 16px;
                width: 224px;
                
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-Google{
                margin-bottom: 16px;
                margin-right: 0px;
                width: 250px;
                max-height: 70px;
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-MacOSButton{
                width: 224px;
                margin-right: 16px;
                margin-bottom: 0px;
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-WindowsButton{
                width: 250px;
              }
            }

            @media screen and (min-width: 781px) and (max-width: $desktop-small) {
              width:80%;
              

              .SubscriptionScreen-mainContent__DownloadLink-iOS{
                margin-right: 16px;
                max-height: 70px;
                margin-bottom: 16px;
                width: 224px;
                
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-Google{
                margin-bottom: 16px;
                margin-right: 0px;
                width: 250px;
                max-height: 70px;
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-MacOSButton{
                width: 224px;
                margin-right: 16px;
                margin-bottom: 0px;
              }
  
              .SubscriptionScreen-mainContent__DownloadLink-WindowsButton{
                width: 250px;
              }
            }
          }
        }
    }


     /* ==== FOOTER SECTION === */

    .RegisterScreenUpdate-FooterContainer{
      @include ContainerStyle($height:auto, $background:$primaryLightBgColor);

        .RegisterScreenUpdate-FooterContainer__ContentBox{
          @include ContainBoxStyle(center, 20px 80px, $primaryLightBgColor, row wrap);

          @media screen and (max-width: $mobile-large) {
           padding: 20px 16px;
           justify-content:center;
          }

          @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
            padding: 20px 16px;
          }

          .RegisterScreenUpdate-FooterContainer__ContentBox--Text{
            width: 80%;

            @media screen and (max-width: $mobile-large) {
              width: 100%;
              text-align: center;
              display: flex;
              flex-flow: column wrap;
              align-items: center;
              justify-content:flex-start;
            }

            @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
              width: calc(60% - 24px);
              padding-right: 24px;
            }
  
            .RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links{
              display: flex;
              flex-flow: row wrap;
              align-items: center;
              justify-content: flex-start;
              text-transform: uppercase;
              margin-bottom: 8px;

              @media screen and (max-width: $mobile-large) {
                width: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content:center;
                margin-bottom: 0px;
              }
              
  
              .RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink{
                margin-right: 24px;
                color: $primaryTextColor;

                @media screen and (max-width: $mobile-large) {
                  margin-bottom:8px;
                  margin-right: 16px;
                }
              }

              .RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink:nth-child(3){
                margin-right: 0px;
              }

              .RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink a {
                font-size: 13px;

                @media screen and (max-width: $mobile-large) {
                  font-size: 11px;
                }
              }

              .RegisterScreenUpdate-FooterContainer__ContentBox__Text__Links__textLink:hover{
                cursor: pointer;
                color:$neutralDark;
              }
            }

            .RegisterScreenUpdate-FooterContainer__ContentBox--BodyText{
              color: $primaryTextColor;
              line-height: 1.5;

              @media screen and (max-width: $mobile-large) {
                width: 100%;
                text-align: center;
                font-size: 11px;
              }

              
            }

          }

          

          .RegisterScreenUpdate-FooterContainer__ContentBox__LogoImages{
            width: 20%;

            @media screen and (max-width: $mobile-large) {
              margin-top:24px;
              width: 70%;
              max-width: 260px;
              text-align: center;
            }

            @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
              width: 40%;
            }

            .RegisterScreenUpdate-FooterContainer__ContentBox__LogoImage-image{
              width: 100%;
            }
          }
        }

        
    }
}