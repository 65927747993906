@import '../../../constants';
@import '../../../buttons';

.LoginScreenUpdate-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: #f8f8f8;

    @media screen and (max-width: $mobile-large) {
      height: auto;
      min-height: 100vh;
    }

    /* ==== FORM SECTION === */

    .LoginScreenUpdate-TopArea {
        width: 100vw;
        height: 100vh;
        background: rgb(105,105,105);
        background: linear-gradient(90deg, rgba(105,105,105,0.39401698179271705) 0%, rgba(239,239,239,1) 100%);
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $mobile-large) {
            align-items: flex-start;
            width: 100vw;
            overflow: hidden;
          }

          @media screen and (max-width: 375px) {
            height: auto;
          }
  
        .LoginScreenUpdate-TopArea__heroimage {
            width: 100vw;
            height: 100vh;
            background-image: url("../../../assets/images/hero-image-screens.webp");
            background-position: center;
            background-repeat: no-repeat;

            @media screen and (max-width: $mobile-large) {
                height: 280px;
                background-size: cover;
                transform: scale(2);
              }
        }
  
        .LoginScreenUpdate-TopArea__header{
              max-width: 1680px;
              height: 80px;
              width: 100vw;
              position: absolute;
              top: 0;
              display: flex;
              flex-flow: row wrap;
              align-items: flex-start;
              justify-content: center;
  
              @media screen and (max-width: $mobile-large) {
                width: 100%;
                padding: 16px;
              }
  
              @media screen and (min-width: $mobile-large) and (max-width: $desktop-Xlarge) {
                  width: 100%;
                  padding: 0px 80px;
              }
  
              .LoginScreenUpdate-TopArea__header-freedrumLogoContainer {
                  display: flex;
                  flex-flow: row wrap;
                  align-items: center;
                  justify-content: flex-start;
                  width: 50%;
                  height: 100%;
              
              }
  
              .LoginScreenUpdate-TopArea__header-logIn{
                  width: 50%;
                  height: 100%;
                  display: flex;
                  flex-flow: row wrap;
                  align-items: center;
                  justify-content: flex-end;
  
                  .LoginScreenUpdate-TopArea__header-logIn-Button{
                      padding: 8px 32px;
                      background-color: $primaryBlue;
                      color: $neutralLightest;
                      border-radius: 20px;
                      border: none;
                      font-family: $font-stack;
                      font-weight: 600;
                  }
  
                  .LoginScreenUpdate-TopArea__header-logIn-Button:hover{
                      padding: 8px 32px;
                      background-color: #2e8400;
                      color: $neutralLightest;
                      cursor: pointer;
                  }
              }
        }
  
        .LoginScreenUpdate-TopArea__FormContainer{
          width:100%;
          height: calc(100% - 280px);
          max-width: 1680px;
          position: absolute;
          display: flex;
          flex-flow: column wrap;
          align-items: center;
          justify-content: center;
  
          @media screen and (min-width: $mobile-large) and (max-width: $desktop-Xlarge) {
            width: 100%;
            padding: 0px 80px;
        }

        @media screen and (max-width: $mobile-large) {
          align-items: center;
          position: relative;
          height: 100%;
        }

        @media screen and (max-width: 375px) {
          align-items: center;
          position: relative;
          height: auto;
        }
  
          .LoginScreenUpdate-TopArea__FormContainer-box{
              width: 488px;
              height: auto;
              border-radius: 20px;
              background-color: $neutralLightest;
              -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
              -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
              box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
              display: flex;
              flex-flow: column wrap;
              align-items: flex-start;
              justify-content: flex-start;
              text-align: left;
              padding: 48px;
              position: relative;

              @media screen and (max-width: $mobile-large) {
                width: 100%;
                padding: 32px;
                border-radius: 20px 20px 0 0;
                height: 100%;
              }
  
              .Login__containerBox-formBox{
                  width: 100%;

                  @media screen and (max-width: $mobile-large) {
                    width: 100%;
                    padding: 0px;
                  }
              }
  
              .LoginScreen-form {
                  width: 100%;
                  height: auto;
                  display: flex;
                  flex-flow: column wrap;
                  align-items: flex-start;
                  justify-content: center;
              
                  @media screen and (max-width: $mobile-large) {
                    width: 100%;
                  }
              
                  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
                    width: 100%;
                  }
              
              }
  
              .LoginScreenUpdate-TopArea__FormContainer-box-title{
                  font-size: 30px;
                  font-weight: 600;
                  margin-bottom: 16px;
                  color: #212121;
              }
  
              .LoginScreenUpdate-TopArea__FormContainer-box-SmallTitle{
                  font-size: 16px;
                  font-weight: 500;
                  width: 100%;
                  margin-bottom: 16px;
                  color: #212121;
              }

              .LoginScreenUpdate-TopArea__FormContainer-box-SmallTitle.span {
                  color: $fdGreen;
              }
  
              .LoginScreen-textBelowInputs {
                  color: $primaryBlue;
                  width: 100%;
                  line-height: 16px;
                  font-size: 11px;
                  display: flex;
                  flex-flow: row wrap;
                  align-items: center;
                  justify-content: flex-start;
                  margin-bottom: 16px;
  
                  @media screen and (max-width: $mobile-large) {
                  width: 90%;
                  }
  
                  @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                  width: 90%;
                  }
              }
              
  
          }
  
            .LoginScreen-registerButton{
                color: $neutralLightest;
                background-color: $fdGreen;
                height: 48px;
                width: 216px;
                border: none;
                border-radius: 30px;
                box-shadow: -2px -2px 5px rgba(white, 1),
                2px 2px 5px rgba(black, 0.1);
                position: absolute;
                bottom: -24px;
                font-family: $font-stack;
                font-weight: 600;
                left: 50%;
                transform: translate(-50%, 0);

                @media screen and (max-width: $mobile-large) {
                    position: relative;
                    bottom: 0px;
                    margin: 48px 0px 16px 0px;
                    height: 56px;
                  }
            }
  
            .LoginScreen-registerButton:hover{
                cursor: pointer;
                background-color: #2e8400;
            }
        }
  
        
    }
}