@import "../../../../constants";
@import "../../../../buttons";

.AddDeviceProfileScreen-container {
  background-color: $primaryLightBgColor;
  width: 100%;
  height: 100vh;
  margin-top: 136px;
}

.AddDeviceProfileScreen-mainContent {
  margin-left: 300px;
  margin-right: 16px;

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin-left: 240px;
  }
}

.AddDeviceProfileScreen-mainContent .ErrorBox-container {
  margin-bottom: 16px;
  width: 80%;
  max-width: 400px;

  @media only screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.AddDeviceProfileScreen-title {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
  margin-bottom: 32px;
  padding-top: 18px;
}

.AddDeviceProfileScreen-info {
  font-size: 16px;
  color: $primaryTextColor;
  margin-bottom: 8px;
}

.AddDeviceProfileScreen-info-consent {
  font-size: 13px;
  color: $primaryTextColor;
  margin-bottom: 28px;
  font-weight: 500;
  font-style: italic;
}

.AddDeviceProfileScreen-form-title {
  font-size: 14px;
  font-weight: 600;
  color: $primaryTextColor;
  margin-bottom: 6px;
}

.AddDeviceProfileScreen-form-info {
  font-size: 11px;
  color: $primaryTextColor;
}

.AddDeviceProfileScreen-form-inputs {
  display: flex;
}

.AddDeviceProfileScreen-form-container {
  width: 224px;
}

.AddDeviceProfileScreen-form-container .FormInput-nameField:nth-child(2) {
  margin-left: 20px;
}

.AddDeviceProfileScreen-form-inputs .FormInput-nameField {
  margin-top: 26px;
}

.AddDeviceProfileScreen-save {
  @include ctaBtn;
  margin-top: 32px;
  margin-bottom: 48px;
}

.AddDeviceProfileScreen-radioButtons-container {
  margin-bottom: 28px;
  margin-top: 19px;
}

/* Customize the label (the container) */
.container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-right: 28px;
  cursor: pointer;
  font-size: 14px;
  color: $primaryTextColor;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 28px;
  width: 28px;
  box-shadow: -1px -1px 3px 0 rgba(255, 255, 255, 1), 1px 1px 3px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

/* Style the indicator (dot/circle) */
.container input:checked ~ .checkmark {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 23, 68, 1) 0%, rgba(255, 23, 68, 1) 40%, rgba(239, 239, 239, 1) 40%, rgba(239, 239, 239, 1) 100%);
  box-shadow: inset -2px -2px 3px rgba(white, 1), inset 3px 3px 3px rgba(black, 0.1);
}

@media screen and (max-width: $mobile-large) {
  .AddDeviceProfileScreen-mainContent {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
  }
  .AddDeviceProfileScreen-container {
    margin-top: 104px;
  }

  .AddDeviceProfileScreen-save {
    width: 100%;
  }

  .AddDeviceProfileScreen-form-container {
    width: 100%;
  }

  .AddDeviceProfileScreen-info {
    font-size: 14px;
    line-height: 1.7;
  }
}
