@import '../../constants';

.FormInput-nameField {
  width: 100%;
  height: 56px;
  border-radius: 10px;
  border: 1px solid $neutralMidDark;
  position: relative;
  margin-top: 32px;
}

.FormInput-nameField:focus-within {
  border: 1px solid $primaryBlue;
  background-color: $primaryLightBgColor;
}

.FormInput-nameLabel {
  font-family: $font-stack;
  font-weight: 600;
  font-size: 13px;
  color: $primaryTextColor;
  position: absolute;
  top: -10px;
  left: 16px;
  background-color: $primaryLightBgColor;
  padding: 0 8px;
}

.FormInput-nameLabel:focus-within {
  color: $primaryBlue;
}

.FormInput-nameInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  border-radius: 10px;
  padding: 0 0 0 16px;
  font-family: $font-stack;
  font-size: 16px;
  color: $primaryTextColor;
}

.FormInput-nameInput::placeholder{
  font-size: 13px;
}

.FormInput-nameInput-textarea {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  border-radius: 10px;
  padding: 16px;
  font-family: $font-stack;
  font-size: 16px;
  color: $primaryTextColor;
  line-height: 1.5;
}
