@import '../../../../constants';

.DeviceSuccess-container {
  background-color: $primaryLightBgColor;
  margin-top: 136px;
  @media screen and (max-width: $mobile-large) {
    margin-top: 104px;
  }
}

.DeviceSuccess-mainContent {
  margin-left: 300px;

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin-left: 240px;
  }

  @media screen and (max-width: $mobile-large) {
    margin-left: 16px;
    margin-right: 16px;
    padding: 18px 0 0 0;
    width: calc(100% - 32px);
  }
}

.DeviceSuccess-header {
  display: inline-block;
  width: calc(100% - 48px);
  margin-bottom: 40px;
}

.DeviceSuccess-mainContent h1 {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
}

.DeviceSuccess-mainContent p {
  font-size: 16px;
  color: $primaryTextColor;
  margin: 24px 0 32px;
  line-height: 26px;
  text-align: left;

  @media screen and (max-width: $mobile-large) {
    font-size: 14px;
    line-height: 1.7;
  }
}

.DeviceSuccess-dialogBox-form-closeButton {
  width: 320px;
  height: 56px;
  background-color: $primaryLightBgColor;
  border-radius: 28px;
  border: none;
  font-size: 14px;
  font-family: $font-stack;
  font-weight: bold;
  color: $fdRed;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 40px 0px;
  display: block;


  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }
}