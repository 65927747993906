@import '../../../constants';

.SubscriptionScreen-container {
  background-color: $primaryLightBgColor;
  width: 100%;
  min-width: $mobile-small;
  height: auto;
  margin-top: 80px;
  margin-right: 48px;

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    margin-top: 140px;
  }
}




