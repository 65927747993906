@import "../../../../constants";
@import "../../../../buttons";

.modal-container {
  width: 100vw;
  height: 100vh;
  background-color:rgba(100,100,100,0.65) ;
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
    padding: 40px 24px;
    position: fixed;

    html, body {
      overflow-y: hidden;
      width: 100vw;
      height: 100vh;
      padding-right: 15px;
    }

    body {
      position: relative;
    }
  }
}

.RemoveFeedbackModal-container {

  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 80px 48px;
  height: auto;
  width: auto;
  justify-content: center;

  @media screen and (max-width: $mobile-large) {
    padding: 40px 24px;
    
  }
}

.ReactModal__Content {
  background-color: $neutralLightest;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  box-shadow: 0px 50px 50px 1px rgba(0, 0, 0, 0.20);
  opacity: 1;

  @media screen and (max-width: $mobile-large) {
    width: 90%;
    height: auto;
  }
}


.RemoveFeedbackModal-title {
  font-size: 28px;
  font-weight: 600;
  color: $neutralDark;
  margin-bottom:  10%;
  width: 380px;
  text-align: center;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }
}


.RemoveFeedbackModal-body {
  color: $primaryTextColor;
  font-size: 14px;
  line-height: 26px;
  width: 380px;
  text-align: center;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }

}


.RemoveFeedbackModal-DeleteBtn{
  @include ctaBtn;
  margin-top: 40px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }
}

.RemoveFeedbackModal-DeleteBtn:hover {
  background: #E6163F;
}

.RemoveFeedbackModal-CloseBtn{
  width: 320px;
  height: 56px;
  background-color: $neutralLightest;
  border-radius: 50px;
  border: 1px solid $neutralMidDark;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $fdRed;
  cursor: pointer;
  margin: 15px auto 0;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }
}

.VerifyEmailModal-resendCodeButton:hover {
  background-color: #ebebeb;
}

