@import "../../constants";
@import "../../buttons";

.Challenge-Admin-Container {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    background-image: url("../../assets/images/background-desktop.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding: 48px 0;

    @media screen and (max-width: $mobile-large) {
        width:100%;
        padding: 24px;
        background: none;
    }

    .Challenge-Admin-Container__Box {
        width: 700px;
        height: auto;
        background-color: #f8f8f8;
        padding: 32px;
        border-radius: 20px;

        @media screen and (max-width: $mobile-large) {
            width:100%;
            padding: 48px 24px;
        }

        .Challenge-Admin-Container__Box-TopArea__header{
            width: 100%;
            height: auto;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 32px;

            @media screen and (max-width: $desktop-small) {
                width:50%;
                padding: 0px 24px 46px;
                margin: auto;
            }

            @media screen and (max-width: $mobile-large) {
                width:100%;
                padding: 0px 16px 24px;
            }
        }

        .Challenge-Admin-Container__Box-Title{
            font-size: 32px;
            color: $neutralDark;
            text-align: center;
            margin-bottom: 24px;
        }

        .Challenge-Admin-Container__Box-FormDivider{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;

            .Challenge-Admin-Container__Box-FormInput{
                width: calc(50% - 16px);

                @media screen and (max-width: $mobile-large) {
                    width:100%;
                }
            }
        }

        .Challenge-Admin-Container__Box-Selector {

            margin: 24px 0 48px;

            label {
                color: $primaryTextColor;
                margin-right: 16px;
                font-weight: 600;
            }

            select {
                width: 200px;
                margin-right: 16px;
                color: $primaryTextColor;

                @media screen and (max-width: $mobile-large) {
                    margin-top: 16px;
                    width: 100%;
                }
            }
        }

        .Challenge-Admin-Container__Box-Notes {
            margin-bottom: 48px;
            color: $primaryTextColor;

            p {
                line-height: 2;

                @media screen and (max-width: $mobile-large) {
                    margin-bottom: 16px;
                }
            }
        }

        .Challenge-Admin-Container__Box-SubmitButton {
            color: $neutralLightest;
            background-color: #4DAE43;
            height: 56px;
            width: 216px;
            border: none;
            border-radius: 30px;
            box-shadow: -2px -2px 5px rgba(white, 1),
            2px 2px 5px rgba(black, 0.1);
            font-family: $font-stack;
            font-weight: 600;
            display: block;
            margin: 0px auto 16px;

            @media screen and (max-width: $mobile-large) {
                margin-bottom: 24px;
            }
        }

        .Challenge-Admin-Container__Box-SubmitButton:focus-within{
            background-color: #0068AF;
          }

        .Challenge-Admin-Container__Box-SubmitButton:hover{
              cursor: pointer;
              background-color: #0068AF;
          }

        .success-alert {
            padding: 16px 32px;
            border: 1px dotted $fdGreen;
            background-color: rgba($fdGreen, 0.4);
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;

            .success-alert_icon{
                width: 20px;
                height: 20px;
            }

            .success-alert_icon:hover {
                cursor: pointer;
            }
        }

        .error-alert {
            padding: 16px 32px;
            border: 1px dotted $fdRed;
            background-color: rgba($fdRed, 0.4);
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;

            .success-alert_icon{
                width: 20px;
                height: 20px;
            }

            .success-alert_icon:hover {
                cursor: pointer;
            }
        }
    }

    .Challenge-Admin-Container__Box-Reset{
        margin-top: 24px;
        width: 700px;
        background-color: #f8f8f8;
        padding: 24px;
        border-radius: 20px;

        @media screen and (max-width: $mobile-large) {
            width:100%;
            padding: 48px 24px;
        }

        .Challenge-Admin-Container__Box-Reset-Form{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
        }

        .Challenge-Admin-Container__Box-Reset-Selector {

            @media screen and (max-width: $mobile-large) {
                margin-bottom: 24px;
                width: 100%;
            }

            label {
                margin-right: 24px;
                
            }

            select {
                width: 200px;

                @media screen and (max-width: $mobile-large) {
                    width:100%;
                    margin-top: 16px;
                }
            }
        }

        .Challenge-Admin-Container__Box-Reset-Button{
            color: $neutralLightest;
            background-color: #4DAE43;
            height: 40px;
            width: 216px;
            border: none;
            border-radius: 30px;
            box-shadow: -2px -2px 5px rgba(white, 1),
            2px 2px 5px rgba(black, 0.1);
            font-family: $font-stack;
            font-weight: 600;
            display: block;
            margin-right: 0;

            @media screen and (max-width: $mobile-large) {
                height: 56px;
                margin: auto;
            }
        }

        .Challenge-Admin-Container__Box-Reset-Button:hover {
            cursor: pointer;
            background-color: #0068AF;
        }


    }

    
}

.confirmation-modal-container{
    width: 100vw;
    height: 100vh;
    background-color: rgba(100, 100, 100, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $mobile-large) {
       position: fixed;
    }

    .confirmation-modal-container_Content{
        width: 640px;
        padding: 48px;
        background-color: $neutralLightest;
        border-radius: 20px;
        -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
        -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
        box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);

        @media screen and (max-width: $mobile-large) {
            width: 95%;
            height: 95%;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            padding: 24px;
         }

        .confirmation-modal-container_Content-title {
            font-size: 32px;
            color: $neutralDark;
            text-align: center;
            margin-bottom: 8px;
        }

        .confirmation-modal-container_Content-title.reset {
            margin-bottom: 32px;
        }

        .confirmation-modal-container_Content-text {
            text-align: center;
            color: $neutralDark;
            margin-bottom: 32px;
        }

        .confirmation-modal-container_Content-text.reset {
            margin-bottom: 16px;
        }

        .confirmation-modal-container_Content-entryBlock {
            line-height: 2;
            text-align: center;
            margin-bottom: 48px;

            @media screen and (max-width: $mobile-large) {
                margin-bottom: 24px;
             }

            .confirmation-modal-container_Content-entryBlock_title {
                color: $primaryTextColor;
                font-weight: 500;
            }

            .confirmation-modal-container_Content-entryBlock_value {
                font-size: 16px;
                font-weight: 600;
                margin-bottom:16px;
            }
        }

        .confirmation-modal-container_Content-ButtonBlock{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $mobile-large) {
                width: 100%;
            }

            .confirmation-modal-container_Content-ButtonBlock_Cancel {
                color: $primaryTextColor;
                background-color: $neutralLightest;
                height: 56px;
                width: 216px;
                border: none;
                border-radius: 30px;
                border: 1px solid $primaryTextColor;
                margin-right: 48px;
                font-family: $font-stack;

                @media screen and (max-width: $mobile-large) {
                    width: calc(50% - 16px);
                    margin: 0 16px 0 0;
                }
            }

            .confirmation-modal-container_Content-ButtonBlock_Cancel:focus-within{
                background-color: $neutralMidDark;
              }
    
            .confirmation-modal-container_Content-ButtonBlock_Cancel:hover{
                  cursor: pointer;
                  background-color: #c9c9c9;
                  color: #464646;
                  border: none;
              }


            .confirmation-modal-container_Content-ButtonBlock_Submit {
                color: $neutralLightest;
                background-color: #4DAE43;
                height: 56px;
                width: 216px;
                border: none;
                border-radius: 30px;
                box-shadow: -2px -2px 5px rgba(white, 1),
                2px 2px 5px rgba(black, 0.1);
                font-family: $font-stack;
                font-weight: 600;
                display: block;

                @media screen and (max-width: $mobile-large) {
                    width: calc(50% - 16px);
                    margin: 0;
                }
            }

            .confirmation-modal-container_Content-ButtonBlock_Submit:focus-within{
                background-color: #0068AF;
              }
    
            .confirmation-modal-container_Content-ButtonBlock_Submit:hover{
                  cursor: pointer;
                  background-color: #0068AF;
              }
        }

        .confirmation-modal-container_Content-ButtonBlock.reset {
            margin-top: 48px;
        }

    }
    
   
}


.footer-text{
    width: 100%;
    height: auto;
    font-size: 13px;
    color: $primaryTextColor;
    margin: 32px 0 0;
    text-align: center;
    line-height: 1.5;

    @media screen and (max-width: 320px) {
        width: 80%;
    }
}