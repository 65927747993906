@import "../../../../constants";

.modal-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(100, 100, 100, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  
    @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
      padding: 40px 24px;
      position: fixed;
    }
  }

  
.ManageSubscriptionModal-container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: 80px 48px;
    height: auto;
    width: auto;
    justify-content: center;
  
    @media screen and (max-width: $mobile-large) {
      padding: 48px 24px;
      overflow-y: hidden;
    }
  }

  .ReactModal__Content {
    background-color: $neutralLightest;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    box-shadow: 0 50px 50px 1px rgba(0, 0, 0, 0.20);
    opacity: 1;
  
    @media screen and (max-width: $mobile-large) {
      width: 90%;
      height: auto;
    }
  }

  .ManageSubscriptionModal-title {
    font-size: 28px;
    font-weight: 600;
    color: $neutralDark;
    margin-bottom: 10%;
    width: 380px;
    text-align: center;
  
    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }
  }

  .ManageSubscriptionModal-body {
    color: $primaryTextColor;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 12px;
    width: 380px;
  
    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }
  }

  .ManageSubscriptionModal-closeButton {
    width:32px;
    height: 32px;
    margin-bottom: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;

    @media screen and (max-width: $mobile-large) {
      width: 24px;
      height: 24px;
      top: 8px;
      right: 16px;
    }

  .ManageSubscriptionModal-closeButton-image {
      width: 32px;
      height: 32px;
      border: none;
      background-size: cover;
      border-radius: 50px;
      box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;
      font-size:30px;
      background-color: $neutralLightest;

      @media screen and (max-width: $mobile-large) {
        ox-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
      }
  
      .ManageSubscriptionModal-closeButton-image-icon {
        font-size: 24px;
        vertical-align: middle;
        position: relative;
        left:1px;
        top:-2px;
        color: $fdRed;

        @media screen and (max-width: $mobile-large) {
          font-size: 20px;
          top:-4px;
        }
      }
  }
}

.RemoveFeedbackModal-CloseBtn{
  width: 320px;
  height: 56px;
  background-color: $neutralLightest;
  border-radius: 50px;
  border: 1px solid $neutralMidDark;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $fdRed;
  cursor: pointer;
  margin: 15px auto 0;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }
}

.RemoveFeedbackModal-CloseBtn:hover {
  border: 2px solid $neutralMidDark;
}