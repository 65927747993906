@import "../../../constants";
@import "../../../buttons";

.modal-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(100, 100, 100, 0.65);
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
    padding: 40px 24px;
    position: fixed;

    html, body {
      overflow-y: hidden;
      width: 100vw;
      height: 100vh;
      padding-right: 15px;
    }

    body {
      position: relative;
    }
  }
}

.VerifyEmailModal-container {

  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 80px 48px;
  height: auto;
  width: auto;
  justify-content: center;

  @media screen and (max-width: $mobile-large) {
    padding: 40px 24px;

  }
}

.ReactModal__Content {
  background-color: $neutralLightest;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  box-shadow: 0px 50px 50px 1px rgba(0, 0, 0, 0.20);
  opacity: 1;

  @media screen and (max-width: $mobile-large) {
    width: 90%;
    height: auto;
  }
}


.VerifyEmailModal-title {
  font-size: 28px;
  font-weight: 600;
  color: $neutralDark;
  margin-bottom: 10%;
  width: 380px;
  text-align: center;

  @media screen and (max-width: $mobile-large) {
    width: 100%;

  }
}


.VerifyEmailModal-body {
  color: $primaryTextColor;
  font-size: 14px;
  line-height: 26px;
  width: 380px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;

  }

}

.VerifyEmailModal-code-container {
  display: flex;
  margin-bottom: 0px;
  margin-top: 24px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;

  }
}

.VerifyEmailModal-code {
  background-color: white;
  width: 50px;
  height: 64px;
  border-radius: 10px;
  margin-left: 11px;
  font-size: 24px;
  font-weight: bold;
  color: $primaryTextColor;
  font-family: $font-stack;

  @media screen and (max-width: $mobile-large) {
    width: 20%;

  }
}

.VerifyEmailModal-code:nth-child(1) {
  margin-left: 0px;
}

.VerifyEmailModal-code-container .VerifyEmailModal-code {
  background: #FFFFFF;

  text-align: center
}

.VerifyEmailModal-verifyButton {
  @include ctaBtn;
  margin-top: 40px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;

  }
}

.VerifyEmailModal-verifyButton:hover {
  background: $blueDark;
}

.VerifyEmailModal-resendCodeButton {
  width: 320px;
  height: 56px;
  background-color: $neutralLightest;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $primaryBlue;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 15px auto 0;

  @media screen and (max-width: $mobile-large) {
    width: 100%;

  }
}

.VerifyEmailModal-resendCodeButton:hover {
  background-color: #ebebeb;
}

