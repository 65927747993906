@import './constants';

.loader-container {
    width: 100vw;
    height: 100vh;
    background-color: $primaryLightBgColor;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%);
}

.loader-container-loading-spinner {
    width: 75px;
    margin-bottom: 24px;
}

.loader-container-text {
    color: $primaryTextColor;
    font-weight: 600;
}