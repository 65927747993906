@import '../../constants';

.SideNavbar-container {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column ;
  position: fixed;
  justify-content: flex-start;
  align-items: flex-start;


  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 240px;
    height: 100%;
  }
}

.SideNavbar-logoutBtn, .SideNavbar-freedrumLogo, .SideNavbar-container__closeBtn {
  display: none;
}

.SideNavbar-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #646464;
  opacity: 0;
  position: absolute;
  z-index: 3;
  visibility: hidden;
}

.Sidebar-list-box {
  width: 100%;
}

.sidebar-options-box {
  width: 100%;
}

.SideNavbar-active {
  font-weight: bold;
  background: linear-gradient(to right, $primaryBlue 0%, $primaryBlue 3%, white 3%, white 100%);
  width: 100%;
}

.SideNavbar-item {
  height: 58px;
  color: $primaryTextColor;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.SideNavbar-container a {
  background-color: $primaryLightBgColor;
  width: 100%;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.SideNavbar-item:hover {
  font-weight: bold;
  background: linear-gradient(to right, $primaryBlue 0%, $primaryBlue 3%, white 3%, white 100%);
}

.SideNavbar-mainItem {
  margin-left: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: flex-start;

  .text-icon {
    margin-left: 8px;
  }
}

.SideNavbar-secondaryItem {
  margin-left: 56px;
}

.SideNavbar-footer {
  padding: 16px 32px ; 
  align-self: flex-end;
  background-color: $primaryLightBgColor;

  @media screen and (max-height: 740px) {
    //visibility: hidden;
    top: calc(100vh - 90px);
    padding: 16px 32px ;
  }
}

.SideNavbar-footer p {
  font-size: 11px;
  color: $primaryTextColor;
  line-height: 1.5;

  @media screen and (max-height: 850px) {
    line-height: 1.5;
  }
}

@media screen and (max-width: $mobile-large) {
  .SideNavbar-content-visible .SideNavbar-container {
    left: 0;
    opacity: 1;
  }

  .SideNavbar-content-visible .SideNavbar-overlay {
    visibility: visible;
    opacity: 65%;
  }

  .SideNavbar-container {
    position: fixed;
    height: 100%;
    background-color: $primaryLightBgColor;
    padding-top: 40px;
    top: 0;
    left: -80%;
    width: 80%;
    opacity: 0;
    transition: left 0.3s, opacity 0.3s;
    z-index: 4;
    overflow-y: scroll;
  }

  .SideNavbar-overlay {
    position: fixed;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  .SideNavbar-freedrumLogo {
    display: block;
    margin-left: 32px;
    margin-top: -16px;
    margin-bottom: 24px;
  }

  .SideNavbar-freedrumLogo > a > img{
    width: 100%;
  }

  .SideNavbar-logoutBtn {
    display: block;
    color: $primaryTextColor;
    font-size: 13px;
    font-weight: bold;
    font-family: $font-stack;
    border: none;
    background: transparent;
    text-align: justify;
    padding: 16px 0px 16px 32px ;
    height: 46px;
    cursor: pointer;
    width: 100%;
  }

  .SideNavbar-item {
    height: 46px;
  }
  

  .SideNavbar-logoutBtn:hover {
    font-weight: bold;
    background: linear-gradient(to right, $primaryBlue 0%, $primaryBlue 3%, white 3%, white 100%);
    width: 100%;
  }

  .SideNavbar-container__closeBtn {
    position: absolute;
    top: 16px;
    display: block;
    right: 16px;
    color: $neutralDark;
    cursor: pointer;
  }
}