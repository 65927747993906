@import "../../../constants";
@import "../../../buttons";

.RegisterScreen-container {
  background: linear-gradient(
                  to left,
                  white 0%,
                  white 40%,
                  white 40%,
                  $primaryLightBgColor 40%,
                  $primaryLightBgColor 100%
  );
  min-height: 100vh;
  min-width: 100vw;
  width: auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  height: auto;

  @media screen and (max-width: $mobile-large) {
    background: $primaryLightBgColor;
    height: auto;
    padding: 32px 0px 0px 0px;
    flex-flow: column wrap;
    justify-content: flex-start;
  }

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    flex-flow: column wrap;
    background: $primaryLightBgColor;
    justify-content: flex-start;
  }

  .RegisterScreen-freedrumLogoContainer {
    order: 1;
    width: 80%;
    height: 40px;
    text-align: left;
    padding-top: 32px;

    @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      width: 100%;
      order: 1;
      text-align: center;
      margin-bottom: 0px;
      margin-top: 32px;
      padding-top: 0px;
    }

    @media screen and (max-width: $mobile-large) {
      margin-top: 0px;
      text-align: center;
      margin-bottom: 0px;
      padding-top: 0px;
      width: 100%;
    }
  }

  .RegisterScreen-container__section {
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    order: 2;

    .RegisterScreen-phonesPictureContainer {
      width: 50%;
      height: auto;
      order: 3;

      .RegisterScreen-phonesPictureContainer__image {
        width: 536px;
        height: auto;
      }

      @media screen and (max-width: $mobile-large) {
        width: 80%;
        order: 2;

        .RegisterScreen-phonesPictureContainer__image {
          width: 100%;
        
        }
      }

      @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
        width: 40%;
        order: 2;
        margin: 0px;

        .RegisterScreen-phonesPictureContainer__image {
          width: 100%;
        }
      }
    }

    .RegisterScreen-phonesPictureContainer.email {
      @media screen and (max-width: $mobile-large) {
        display: none;
      }
    }

  }


  .RegisterScreen-formContainer {
    width: 50%;
    display: flex;
    flex-flow: column wrap;
    order: 2;
    padding-left: 10%;
    min-height: calc(100% - 40px);
    height: auto;
    justify-content: center;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
      order: 3;
      padding: 32px 16px 48px 16px;
    }

    @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      width: 100%;
      order: 3;
      padding: 32px 16px 48px 16px;
      align-items: center;
      height: auto;
      min-height: fit-content;
    }

    .RegisterScreen-formContainer-backButton {
      width: 100%;
      height: 48px;
      margin-bottom: 24px;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: $mobile-large) {
        justify-content: center;
      }

      @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
        width: 450px;
        justify-content: center;
      }


      .RegisterScreen-formContainer-backButton-image {
        width: 48px;
        height: 48px;
        border: none;
        background-size: cover;
        border-radius: 50px;
        background-color: #d8d8d8;
        /*box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);*/
        cursor: pointer;
        font-size: 30px;

        .RegisterScreen-formContainer-backButton-image-icon {
          font-size: 30px;
          vertical-align: middle;
          position: relative;
          left: -1px;
          top: -2px;
        }
      }


    }

  }


  .RegisterScreen-formContainer-title {
    font-size: 24px;
    font-family: $font-stack;
    font-weight: 600;
    margin-bottom: 24px;
    color: $neutralDark;
    width: 80%;
    padding-right: 0px;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
      margin-bottom: 32px;
      text-align: center;
      padding-right: 0px;
      font-size: 20px;
    }

    @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      width: 50%;
      text-align: center;
      padding-right: 0px;
    }
  }

  .RegisterScreen-formContainer-title.one {
    text-align: center;
    margin-bottom: 48px;
  }

  .RegisterScreen-form {
    width: 80%;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }

    @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
      width: 450px;
    }

  }

  .RegisterScreen-form.one {
    align-items: center;
  }

  .RegisterScreen-textBelowInputs-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;

    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
      width: 450px;
    }
  }

  .RegisterScreen-textBelowInputs {
    color: $primaryTextColor;
    width: 90%;
    line-height: 16px;
    font-size: 11px;

    @media screen and (max-width: $mobile-large) {
      width: 90%;
    }

    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
      width: 90%;
    }
  }

  .RegisterScreen-textBelowInputs.password {
    width: 100%;
    margin-top: 8px;
  }

  .RegisterScreen-form-container {
    width: 100%;
    text-align: center;
  }

  .RegisterScreen-registerButton {
    @include ctaBtn;
    margin: auto;


    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }
  }

  .RegisterScreen-registerButton:hover {
    background: #E6163F;
  }


  .RegisterScreen-registerButton-Disable {
    @include ctaBtn-Disable;
    margin: auto;


    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }
  }

  .RegisterScreen-separator {
    width: 100%;
    max-width: 350px;
    height: auto;
    position: relative;
    margin: 32px 0px;
    text-align: center;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
      padding: 0;
    }

    .RegisterScreen-separatorText {
      font-family: $font-stack;
      font-size: 13px;
      color: $primaryTextColor;
      font-weight: 600;
      position: relative;
      text-align: center;
    }

    .RegisterScreen-separatorText::before {
      @include separatorIcons("assets/images/drumstick-separator-left.svg", 5px);
      left: 0;

      @media only screen and (min-width: 320px) and (max-width: 640px) {
        width: 125px;
      }

      @media only screen and (min-width: 360px) and (max-width: 640px) {
        width: 145px;
      }
    }

    .RegisterScreen-separatorText::after {
      @include separatorIcons("assets/images/drumstick-separator-right.svg", 5px);
      right: 0;

      @media only screen and (min-width: 320px) and (max-width: 640px) {
        width: 125px;
        background-position: right;
      }

      @media only screen and (min-width: 360px) and (max-width: 640px) {
        width: 145px;
        background-position: right;
      }

    }
  }

  .RegisterScreen-termsAndConditions {
    font-family: $font-stack;
    color: $primaryTextColor;
    width: 100%;
    line-height: 1.5;
    margin-top: 24px;
    margin-bottom: 48px;
    font-size: 13px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }

    .RegisterScreen-termsAndConditions__button {
      border: none;
      font-family: $font-stack;
      color: $primaryTextColor;
      font-size: 13px;
      text-align: left;
      line-height: 1.5;

    }

    .RegisterScreen-termsAndConditions__label {
      position: relative;
      cursor: pointer;
      padding-left: 0px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
    }

    .RegisterScreen-termsAndConditions__label input[type="checkbox"] {
      clip: rect(0 0 0 0);
      padding: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
      position: absolute;
      top: -2px;
      left: -42px;
      border: 0;
    }

    .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom {
      display: block;
      background-color: $primaryLightBgColor;
      box-shadow: inset -2px -2px 5px rgba(white, 1),
      inset 2px 2px 5px rgba(black, 0.1);
      border-radius: 3px;
    }

    .checkbox-custom:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .RegisterScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom:after {
      display: block;
    }

    .RegisterScreen-termsAndConditions__label .checkbox-custom:after {
      position: relative;
      margin: auto;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid $primaryBlue;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }


    .RegisterScreen-termsAndConditions__checkbox {
      position: relative;
      top: -9px;
      margin-right: 10px;
      border: none;
      width: 20px;
    }

    .checkbox-custom {
      width: 25px;
      height: 25px;
      display: block;
      background-color: $primaryLightBgColor;
      box-shadow: -2px -2px 5px rgba(white, 1),
      2px 2px 5px rgba(black, 0.1);
      border-radius: 3px;
      margin-right: 16px;
      background-size: cover;
    }

    .RegisterScreen-termsAndConditions__label > p {
      width: 85%;
    }


    .RegisterScreen-termsAndConditions__textLink {
      color: $primaryBlue;
    }

  }

  .RegisterScreen-askLogin {
    width: 80%;
    text-align: center;
    font-family: $font-stack;
    font-size: 13px;
    color: $primaryTextColor;
    margin-top: 40px;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }

    .RegisterScreen-askLoginButton {
      font-weight: 600;
      color: $primaryBlue;
      cursor: pointer;
    }

  }

  .RegisterScreen-askLogin.one {
    width: 100%;
  }

  .RegisterScreen-infoIcon {
    margin-right: 6px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    width: 20px;
    font-size: 16px;
  }

  .RegisterScreen-wrongPasswordIcon {
    margin-right: 6px;
    color: $fdRed;
    position: relative;
    top: 2px;
  }


}

