@import '../../../../constants';

.DeviceProfileScreen-container {
  background-color: $primaryLightBgColor;
  margin-top: 136px;
}

.DeviceProfileScreen-container .SuccessBox-container {
  margin-bottom: 24px;
}

.DeviceProfileScreen-mainContent {
  margin-left: 300px;
  padding-top: 18px;
  width: calc(40% - 60px);

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin-left: 240px;
  }
}

.DeviceProfileScreen-header {
  display: inline-block;
  width: calc(100% - 48px);
  margin-bottom: 40px;
}

.DeviceProfileScreen-title {
  font-size: 18px;
  color: $neutralDark;
  font-weight: 600;
}

.DeviceProfileScreen-description {
  color: $primaryTextColor;
  font-size: 13px;
  font-weight: normal;
  line-height: 26px;
  margin-top: 8px;
}

.DeviceProfileScreen-addButton {
  display: inline-block;
  float: right;
  width: 48px;
  height: 48px;
  box-shadow: -3px -3px 3px 0 rgba(255, 255, 255, 1), 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
}

.DeviceProfileScreen-phoneContainer {
  min-height: 128px;
  margin-bottom: 16px;
}

.DeviceProfileScreen-OS-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $neutralLightest;
  height: 64px;
  border-radius: 14px 14px 0 0;
}

.DeviceProfileScreen-model-container {
  min-height: 64px;
  border-radius: 0 0 14px 14px;
  background-color: $neutralLightest;
}

.DeviceProfileScreen-OS-text {
  margin-left: 24px;
  font-size: 14px;
  font-weight: bold;
  color: $primaryTextColor;
}

.DeviceProfileScreen-count {
  margin: 0 24px;
  font-size: 14px;
  color: $primaryTextColor;
}

.DeviceProfileScreen-model-text {
  background-color: white;
  display: flex;
  padding: 24px;
  justify-content: space-between;
}

.DeviceProfileScreen-model-text span {
  line-height: 24px;
  font-size: 14px;
  margin-right: 40px;
  color: $primaryTextColor;
}

.DeviceProfileScreen-model-image {
  margin-left: 24px;
  cursor: pointer;
}

@media screen and (max-width: $mobile-large) {
  .DeviceProfileScreen-container {
    margin-top: 104px;
  }

  .DeviceProfileScreen-mainContent {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
  }

  .DeviceProfileScreen-header {
    width: 80%;
  }

  .DeviceProfileScreen-description {
    line-height: 1.7;
  }
}

@media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
  .DeviceProfileScreen-mainContent {
    width: calc(100% - 240px - 32px);
    margin-left: 240px;
  }
}

