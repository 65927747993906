@import '../../../constants';
@import "../../../buttons";

.onboarding-container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: $primaryLightBgColor;
  padding-top: 48px;

  @media only screen and (max-width: $mobile-large) {
    width: 100%;
    height: auto;
    padding-top: 0;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    padding-top: 48px;
    padding-bottom: 48px;
    height: auto;
  }
}

.onboarding-container-freedrumLogoContainer {
  order: 1;
  width: 100%;
  height: 40px;
  text-align: center;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobile-large) {
    width: 100%;
    order: 1;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 0;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 100%;
    order: 1;
    text-align: center;
    margin-bottom: 48px;
  }
}

.onboarding-dialogBox-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $mobile-large) {
    width: 100%;
    height: auto;
    padding: 20px 16px 48px 16px;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    height: auto;
  }

  h1 {
    color: $neutralDark;
    font-size: 40px;
    font-weight: 500;

    @media only screen and (max-width: $mobile-large) {
      font-size: 30px;
    }
  }
}

.onboarding-success-text {
  font-size: 18px;
  color: $primaryTextColor;
  margin: 24px 0 32px;
  line-height: 1.5;
  text-align: center;
  width: 50%;

  @media only screen and (max-width: $mobile-large) {
    font-size: 14px;
    width: 90%;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 80%;
  }
}


.onboarding-dialogBox-container-text {
  font-size: 18px;
  color: $primaryTextColor;
  margin: 24px 0 0 0;
  line-height: 1.5;
  text-align: center;
  width: 50%;

  @media only screen and (max-width: $mobile-large) {
    font-size: 14px;
    width: 90%;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 80%;
  }
}

.onboarding-dialogBox-container-consent {
  font-size: 13px;
  color: $primaryTextColor;
  margin: 4px 0 32px 0;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  font-style: italic;
  width: 50%;

  @media only screen and (max-width: $mobile-large) {
    font-size: 12px;
    width: 90%;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 80%;
  }
}

.onboarding-dialogBox {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  color: $primaryTextColor;
  background-color: $neutralLightest;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, .2);
  padding: 60px 40px;
  width: 50%;
  margin-bottom: 48px;

  @media only screen and (max-width: $mobile-large) {
    width: 100%;
    min-height: 500px;
    padding: 40px 32px;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 70%;
    min-height: 500px;
    padding: 80px 40px;
  }
}

.onboarding-dialogBox h2 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.onboarding-dialogBox h2:nth-of-type(2) {
  margin-top: 40px;
}

.onboarding-dialogBox-form {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.onboarding-dialogBox-form-body {
  width: 100%;
  font-size: 13px;
  margin: 16px 0 32px 0;
  text-align: center;
}

.onboarding-dialogBox-form .ErrorBox-container {
  margin-bottom: 32px;
  width: 80%;

  @media only screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.onboarding-dialogBox-container-text-error {
  font-size: 13px;
  width: 100%;
  padding: 0 0 0 16px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: $fdRed;
  margin: 0;
  text-align: left;
}

.onboarding-dialogBox-form-checkBoxes-container {
  width: 60%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $mobile-small) and (max-width: 460px) {
    width: 90%;
  }

  @media screen and (min-width: 461px) and (max-width: $mobile-large) {
    width: 80%;
  }

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 75%;
  }
}

.onboarding-dialogBox-form-checkBoxes {
  font-family: $font-stack;
  color: $primaryTextColor;
  width: 50%;
  line-height: 1.5;
  padding: 8px 0;
  font-size: 13px;
  position: relative;

  @media screen and (max-width: $mobile-large) {
    width: 50%;
  }

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 40%;
  }
}

.onboarding-dialogBox-form-checkBoxes__label {
  position: relative;
  cursor: pointer;
  padding-left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  height: auto;
}

.onboarding-dialogBox-form-checkBoxes .checkbox-text {
  width: 100%;
  margin: 0;
  text-align: center;
  padding-left: 0;
  font-size: 13px;
}

.onboarding-dialogBox-form-checkBoxes .checkbox-text.first {
  text-align: left;
  position: absolute;
  left: 40%;
}

.onboarding-dialogBox-form-checkBoxes .checkbox-text.second {
  text-align: left;
  position: absolute;
  left: 40%;
}

.onboarding-dialogBox-form-checkBoxes__label input[type="checkbox"] {
  clip: rect(0 0 0 0);
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  left: -42px;
  border: 0;
}

.onboarding-dialogBox-form-checkBoxes__android input[type="checkbox"] {
  clip: rect(0 0 0 0);
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  left: -42px;
  border: 0;
}

.onboarding-dialogBox-form-checkBoxes__label input[type="checkbox"]:checked ~ .checkbox-custom {
  display: block;
  background-color: $neutralLightest;
  box-shadow: inset -2px -2px 5px rgba(white, 1),
  inset 2px 2px 5px rgba(black, 0.1);
  border-radius: 3px;
}

.onboarding-dialogBox-form-checkBoxes .checkbox-custom:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.onboarding-dialogBox-form-checkBoxes__label input[type="checkbox"]:checked ~ .checkbox-custom:after {
  display: block;
}

.onboarding-dialogBox-form-checkBoxes__label .checkbox-custom:after {
  left: 9px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid $primaryBlue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.onboarding-dialogBox-form-checkBoxes .checkbox-custom {
  width: 25px;
  height: 25px;
  display: block;
  background-color: $neutralLightest;
  box-shadow: -2px -2px 5px rgba(white, 1),
  2px 2px 5px rgba(black, 0.1);
  border-radius: 3px;
  margin-right: 0;
  background-size: cover;
}

.onboarding-dialogBox-form-checkBoxes .checkbox-custom.first {
  position: absolute;
  left: 40%;
  transform: translate(-36px);
}

.onboarding-dialogBox-form-checkBoxes .checkbox-custom.second {
  position: absolute;
  left: 40%;
  transform: translate(-36px);
}

.onboarding-dialogBox-form-Button {
  @include ctaBtn;
  margin-top: 40px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.onboarding-dialogBox-form-inputs-container {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: -32px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.onboarding-dialogBox-form-inputs {
  width: calc(50% - 8px);

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 80%;
  }
}

.onboarding-dialogBox-form-inputs.one {
  margin-right: 8px;

  @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
    margin-right: 0;
  }
}

.onboarding-dialogBox-form-inputs.two {
  margin-left: 8px;

  @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
    margin-left: 0;
  }
}

.onboarding-dialogBox-form-closeButton {
  width: 320px;
  height: 56px;
  background-color: $primaryLightBgColor;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $fdRed;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 15px auto 0;
}
