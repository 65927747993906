@import "../../../../constants";

.FeedbackScreen-container {
  background-color: $primaryLightBgColor;
  height: 100%;
  margin-top: 136px;
}

#FeedbackScreen-mainContent {
  padding-top: 18px;
  margin-left: 300px;
  width: calc(40% - 60px);
}

#FeedbackScreen-mainContent .Feedback-container {
  margin: 0 24px 24px;
  padding: 16px;

  @media screen and (max-width: $mobile-large) {
    margin: 0 0 24px;
  }
}

#FeedbackScreen-mainContent.fullWidth .Feedback-container {
  margin: 24px;
  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin: 24px;
  }
  @media screen and (max-width: $mobile-large) {
    margin: 24px;
  }
}

#FeedbackScreen-mainContent.fullWidth {
  width: calc(100% - 300px - 120px);
  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: calc(100% - 240px - 120px);
  }
}

.FeedbackScreen-content-container {
  width: 50%;
}

.FeedbackScreen-header {
  display: inline-block;
  width: calc(100% - 48px);
  margin-bottom: 40px;
}

.FeedbackScreen-title-span {
  font-size: 18px;
  color: $neutralDark;
  font-weight: 600;
  margin-top: 18px;
  line-height: 22px;
  margin-right: 8px;
}

.FeedbackScreen-title {
  margin-bottom: 5px;
}

.FeedbackScreen-description {
  color: $primaryTextColor;
  font-size: 13px;
  margin-top: 8px;
  font-weight: normal;
  line-height: 26px;
}

.FeedbackScreen-addButton {
  display: inline-block;
  float: right;
  width: 48px;
  height: 48px;
  box-shadow: -3px -3px 3px 0 rgba(255, 255, 255, 1), 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
}

@media screen and (max-width: $mobile-large) {
  .FeedbackScreen-container {
    margin-top: 104px;
  }

  .FeedbackScreen-header {
    margin-bottom: 32px;
    width: 80%;
  }

  #FeedbackScreen-mainContent {
    margin-left: 16px;
    position: relative;
    width: calc(100% - 32px);
  }

  #FeedbackScreen-mainContent.fullWidth {
    width: calc(100% - 32px);
  }

  .FeedbackScreen-description {
    line-height: 1.7;
  }
}

.FeedbackScreen-feedback-versionInfo {
  background-color: $neutralLightest;
  display: flex;
  padding: 21px 24px 22px;
  justify-content: space-between;
  border-radius: 10px;
  font-size: 14px;
  color: #696969;
  font-weight: bold;
}

.FeedbackScreen-feedback-versionData {
  display: inline-flex;
  align-items: center;
}

.FeedbackScreen-feedbackContent {
  background-color: $neutralLightest;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 48px;
}

.FeedbackScreen-feedbackList {
  display: flex;
  flex-wrap: wrap;
}

.FeedbackScreen-feedbackList > div {
  flex: 0 0 calc(100% - 48px);
  margin-left: 24px;
  margin-bottom: 30px;
  background-color: white;
}

#FeedbackScreen-stars-container {
  display: contents;
}

.FeedbackScreen-version {
  margin-right: 8px;
}

.fullWidth .FeedbackScreen-collapsable {
  @media screen and (min-width: $desktop-small) {
    display: flex;
    flex-wrap: wrap;
  }
}

.FeedbackScreen-collapsable {
  overflow: hidden;
  transition: max-height 0.3s, margin-top 0.3s;
  background-color: $neutralLightest;
  border-radius: 10px;

  @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
    display: block;
  }
}

.FeedbackScreen-chevron-collapsable {
  transition: 300ms ease all;
  margin-top: auto;
  margin-bottom: auto;
}

.FeedbackScreen-collapsable.FeedbackScreen-collapsed {
  max-height: 0;
  margin-top: 0;
}

.FeedbackScreen-chevron-collapsable.FeedbackScreen-chevron-collapsed {
  transform: rotate(180deg);
}

@media screen and (max-width: $mobile-large) {
  .FeedbackScreen-feedbackList > div {
    flex: 0 0 100%;
    margin-left: 0px;
    margin-bottom: 30px;
    background-color: white;
  }
}

@media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
  #FeedbackScreen-mainContent {
    width: calc(100% - 240px - 32px);
    margin-left: 240px;
  }
}

@media screen and (min-width: $desktop-small) {
  .fullWidth .Feedback-container {
    flex: 0 0 calc(50% - 48px);
  }
}
