@import "../../constants";


.ErrorBox-container {
    width: 100%;
    height: auto;
    font-family: $font-stack;
    font-weight: 600;
    color: $fdRed;
    font-size: 11px;
    background-color: #FBE6E4;
    padding: 16px 16px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #FF95A9;
    max-width: 700px;
    margin-bottom: 32px;

    @media screen and (max-width: $mobile-large) {
        max-width: 100%;
        width: 90%;
        margin: 0px 16px 32px;
      }

      @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
        width: 570px;
      }
  }


  .ErrorBox-container p {
      font-weight: 500;
      margin-left: 16px;
      line-height: 1.5;
  }


  .ErrorBox-container span {
      font-weight: bold;
  }

  .ErrorBox-container button {
    font-weight: bold;
    border: none;
    font-family: $font-stack;
    color: $fdRed;
    cursor: pointer;
    background: transparent;
}