@import "../../constants";
@import "../../buttons";

.freedrumStudioLogo{
width: 200px;

@media screen and (max-width: $mobile-large)  {
  width:50%;
  margin-right: 24px;
}
}

.Highscore-container {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    background-image: url("../../assets/images/DNB_Course.jpg");
    background-position: center;
    background-size:150% auto;
    background-repeat: repeat-y;
    padding: 48px 0;

    @media screen and (max-width: $tablet) {
        background-image: url("../../assets/images/DNB_Mobile.jpg");
        padding: 0;
    }

    @media screen and (max-width: $desktop-small) {
        justify-content: flex-start;
      }

    .Highscore-container__Box__Timer {
        background-color: $primaryLightBgColor;
        padding: 16px 8px;
        margin: 16px;
        border-radius: 32px;
    }

    .Highscore-container__Box__social_media {
         width: 32px;
         height: 32px;
         margin: 32px 8px 0;
    }

    .Highscore-container__Box{
        width: 760px;
        height: auto;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $desktop-small) {
            width:100%;
            padding: 46px 24px;
          }

        .Highscore-container__InnerBox-TopArea__header{
            width: 100%;
            height: auto;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 32px;

            @media screen and (max-width: $desktop-small) {
                width:50%;
                padding: 0px 24px 46px;
            }

            @media screen and (max-width: $mobile-large) {
                width:100%;
                padding: 0px 16px 24px;
            }
        }

        .Highscore-container__Box__title{
            color: $primaryTextColor;
            font-size: 16pt;
            font-weight: 600;
            text-align: center;
        }

        .Highscore-container__Box__DayChanger{
            width: 300px;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            margin: 32px 0;

            @media screen and (max-width: $mobile-large) {
                width:80%;
              }

            .page-day {
                width: calc(100% - 60px);
                margin: auto;
                font-size: 20px;
                color: $primaryTextColor;
                font-weight: 600;
                text-align: center;
            }
            .page-day.left {
                padding-left: 30px;
            }

            .page-day.right {
                padding-right: 30px;
            }

            .button {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                box-shadow: -3px -3px 5px rgba(white, 1),
                3px 3px 5px rgba(black, 0.2);

                .button-icon{
                    font-size: 32px;
                    vertical-align: middle;
                    color:#696969;
                }

                .button-icon:hover {
                    color: #4DAE43;
                }
                
            }

            .button:hover {
                cursor: pointer;
            }

            .button.hidden {
                display: none;
            }
        }



        .Highscore-container__Box__ScoreContainer{
            width: 100%;
            overflow: scroll;
            height: 540px;
            position: relative;
            padding: 24px;
            margin-bottom: 32px;

            @media screen and (max-width: $tablet) {
                min-height: 0;
              }

            .Highscore-container__Box__ScoreContainer_Firstitem{
                width: 100%;
                height: 112px;
                background-color: $neutralLightest;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                margin: auto;
                border-radius: 20px;
                -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
                -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
                box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
                position: sticky;

                @media screen and (max-width: $tablet) {
                    width:100%;
                    margin-top: 24px;
                  }

                .Highscore-container__Box__ScoreContainer_Firstitem__NoItem {
                    text-align: center;
                    width: 100%;
                    font-size: 32px;
                    font-weight: 500;
                    color: #4DAE43;

                    @media screen and (max-width: $mobile-large) {
                        font-size: 24px;
                    }
                }

                .Highscore-container__Box__ScoreContainer_Firstitem_number{
                    font-size: 20px;
                    width: 5%;
                    padding-left:32px;
                    color: $primaryTextColor;

                    @media screen and (max-width: $mobile-large) {
                        padding-left: 24px;
                    }
                    
                }

                .Highscore-container__Box__ScoreContainer_Firstitem_name{
                    font-size: 32px;
                    width: 40%;
                    padding-left: 0px;
                    font-weight: 500;
                    color: #4DAE43;

                    @media screen and (max-width: $mobile-large) {
                        font-size: 24px;
                        padding-left: 16px;
                    }
                }

                .Highscore-container__Box__ScoreContainer_Firstitem_score{
                    font-size: 32px;
                    width: 40%;
                    padding-right:32px;
                    font-weight: 500;
                    text-align: right;
                    color: #4DAE43;

                    @media screen and (max-width: $mobile-large) {
                        font-size: 24px;
                        padding-right: 24px;
                    }
                }
            }

            .Highscore-container__Box__ScoreContainer_items{
                width: 90%;
                padding: 56px 24px 24px;
                display: flex;
                flex-flow: column wrap;
                justify-content: center;
                align-items: center;
                margin: auto;
                background-color: $primaryLightBgColor;
                -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
                -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
                box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.16);
                margin-top: -24px;
                border-radius: 0 0 10px 10px; 

                .Highscore-container__Box__ScoreContainer_items-container{
                    width: 90%;
                    padding:  24px 24px;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    align-items: center;
                    margin: auto;
                    background-color: #f8f8f8;
                    color: $primaryTextColor;
                    margin-bottom: 16px;
                    border-radius: 10px;

                    @media screen and (max-width: $mobile-large) {
                        width: 100%;
                        padding:  24px 16px;
                    }

                    .Highscore-container__Box__ScoreContainer_items_number{
                        font-size: 20px;
                        width: 5%;

                        @media screen and (max-width: $mobile-large) {
                            font-size: 16px;
                            width: 10%
                        }
                    }
    
                    .Highscore-container__Box__ScoreContainer_items_name{
                        font-size: 24px;
                        width: 40%;

                        @media screen and (max-width: $mobile-large) {
                            font-size: 16px;
                            width: 35%
                        }
                    }
    
                    .Highscore-container__Box__ScoreContainer_items_score{
                        font-size: 24px;
                        width: 40%;
                        text-align: right;

                        @media screen and (max-width: $mobile-large) {
                            font-size: 16px;
                            width: 35%
                        }
                    }
                }
            }

        }
    }

    .Highscore-container__Box__RegisterButton{

        display: none;

        @media screen and (max-width: $tablet) {
            color: $neutralLightest;
              background-color: #4DAE43;
              height: 56px;
              width: 80%;
              border: none;
              border-radius: 30px;
              box-shadow: -2px -2px 5px rgba(white, 1),
              2px 2px 5px rgba(black, 0.1);
              font-family: $font-stack;
              font-weight: 600;
              display: block;
          }
    }

    .Highscore-container__Box__RegisterButton:focus-within{
        background-color: #0068AF;
      }

    .Highscore-container__Box__RegisterButton:hover{
          cursor: pointer;
          background-color: #0068AF;
      }

    .footer-text{
        width: 100%;
        height: auto;
        font-size: 13px;
        color: $primaryTextColor;
        margin: 32px 0;
        text-align: center;
        line-height: 1.5;

        @media screen and (max-width: 320px) {
            width: 80%;
        }
    }
}