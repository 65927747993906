@import '../../../../constants';
@import '../../../../buttons';

.Overview-container {
  background-color: $primaryLightBgColor;
  width: 100%;
  margin-top: 136px;
  margin-bottom: 80px;
}

.Overview-mainContent {
  margin-left: 300px;

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin-left: 240px;
  }
}

.Overview-infoTextContainer {
  width: 50%;
  margin-top: 30px;
  display: inline-block;
}

.Overview-infoTitle {
  color: $primaryTextColor;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.Overview-regularText {
  color: $primaryTextColor;
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
}

.Overview-betaInfo {
  position: relative;
  right: 120px;
  width: calc(50% - 140px);
  top: 30px;
  text-align: right;
  float: right;
  display: inline-block;
}

.Overview-belowPicture-container {
  display: flex;
  margin-top: 64px;
  flex-flow: row wrap;
  width: 90%;
}

.Overview-DeviceProfile-container {
  width: calc(50% - 48px);
  margin-right: 48px;
}

.Overview-DeviceProfile-header {
  display: inline-block;
  width: calc(100% - 48px);
}

.Overview-addButton {
  @include addButton;
}

.Overview-DeviceProfile-list-container {
  min-height: 128px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.Overview-DeviceProfile-OS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $neutralLightest;
  height: 64px;
  border-radius: 14px 14px 0 0;
}

.Overview-OS-text {
  margin-left: 24px;
  font-size: 14px;
  font-weight: bold;
  color: $primaryTextColor;
}

.Overview-count {
  margin: 0 24px;
  font-size: 14px;
  color: $primaryTextColor;
}

.Overview-DeviceProfile-Devices {
  min-height: 64px;
  border-radius: 0 0 14px 14px;
  background-color: $neutralLightest;
}

.Overview-phoneModel {
  background-color: white;
  display: flex;
  padding: 24px;
  justify-content: space-between;
}

.Overview-phoneModel span {
  line-height: 24px;
  font-size: 14px;
  margin-right: 40px;
  color: $primaryTextColor;
}

.Overview-removeDevice {
  margin-left: 24px;
  cursor: pointer;
}

.Overview-belowPicture-title {
  font-size: 24px;
  color: $neutralDark;
  font-weight: 600;
  margin-bottom: 5px;
}

.Overview-belowPicture-description {
  color: $primaryTextColor;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
}

#Overview-betaInfo-title {
  color: $primaryTextColor;
  font-size: 18px;
  font-weight: 600;
}

#Overview-betaInfo-version {
  color: $primaryTextColor;
  font-size: 18px;
  line-height: 35px;
}

#Overview-betaInfo-updated {
  color: $primaryTextColor;
  font-size: 14px;
  font-style: italic;
}

#Overview-topTitle {
  color: $neutralDark;
  font-size: 18px;
  padding-top: 18px;
  font-weight: bold;
}

#Overview-img-container {
  width: 100%;
  margin-top: 35px;
  background-position: center;
  height: 335px;
  background-image: url("../../../../assets/images/overview-picture.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

#Overview-OS {
  font-size: 14px;
  font-weight: bold;
  color: $primaryTextColor
}

#Overview-count {
  color: $primaryTextColor;
  font-size: 14px;
  font-weight: normal;
}

.Overview-removeDevice {
  float: right;
}

@media screen and (max-width: $mobile-large) {
  .Overview-container {
    margin-top: 104px;
  }

  #Overview-topTitle {
    margin-left: 16px;
    margin-right: 16px;
  }

  .Overview-mainContent {
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }

  .Overview-infoTextContainer {
    width: auto;
    margin-left: 16px;
    margin-right: 16px;
  }

  .Overview-betaInfo {
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
    right: 0;
    top: 0;
    float: none;
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 3%;
    background-color: $neutralLightest;
  }

  .Overview-infoTitle {
    font-size: 24px;
  }

  .Overview-DeviceProfile-container {
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-right: 32px;
  }

  .Overview-belowPicture-container {
    flex-direction: column;
    width: 100%;
  }

  .Overview-regularText {
    font-size: 14px;
    line-height: 1.7;
  }

}


@media screen and (min-width: $mobile-large) and (max-width: 855px) {
  .Overview-DeviceProfile-container {
    width: 92%;
    margin-left: 0px;
    margin-right: 0;
  }

  .Overview-belowPicture-container {
    flex-direction: column;
    width: 100%;
  }

  .Overview-belowPicture-description {
    max-width: 239px;
  }

}


@media screen and (min-width: 856px) and (max-width: $desktop-small) {
  .Overview-DeviceProfile-container {
    width: calc(50% - 32px);
    margin-left: 0px;
    margin-right: 32px;
  }

  .Overview-belowPicture-container {
    flex-direction: row;
    width: 100%;
  }

}

@media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
  .Overview-betaInfo {
    right: 48px;
    margin-top: 46px;
  }
}