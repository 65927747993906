@import '../../../constants';
@import '../../../buttons';

.redeem__contentBox {
    background-color: $primaryLightBgColor;
      width: 100%;
      height: 100vh;
      margin-top: 80px;
      margin-right: 48px;

    .redeem__contentBox-header {
        height: 80px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 32px;
        margin-left: 356px;
        width: 40%;


        @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
            margin-left: 264px;
            width: calc(100% - 240px - 120px)
        }

        @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
            margin-left: 264px;
            width: calc(100% - 240px - 48px)
        }

        @media screen and (max-width: $mobile-large) {
            margin-left: 16px;
            width: calc(100% - 32px);
            padding-bottom: 24px;
          }

        .redeem__containerBox-freedrumLogoContainer{
          width: 700px;
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
          position: relative;

          @media screen and (min-width: $mobile-large) and (max-width: $tablet)  {
            width: 520px;
          }

          @media screen and (max-width: $mobile-large)  {
            width:100vw;
          }

          .freedrumStudioLogo{
            width: 200px;
  
            @media screen and (max-width: $mobile-large)  {
              width:50%;
              margin-right: 24px;
            }
          }
  
          .amazonLogo {
            width: 100px;
  
            @media screen and (max-width: $mobile-large)  {
              width:25%;
            }
          }
        }

        

        @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
            padding-top: 32px;
            height: 80px;
        }

        @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
            padding: 32px 16px 0px 16px;
            height: 80px;
            justify-content: space-between;
        }

        .RedeemScreen-askLogin {
            text-align: center;
            font-family: $font-stack;
            font-size: 13px;
            color: $primaryTextColor;
            position: absolute;
            right: 64px;
        
            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                right: 24px;
            }
        
            .RedeemScreen-askLoginButton {
              font-weight: 600;
              color: $primaryBlue;
              cursor: pointer;
            }
        }
    }

    .redeem__containerBox {
        min-height: 800px;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        margin-top:56px;
        margin-left: 356px;
        width: 40%;


        @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
            margin-left: 264px;
            width: calc(100% - 240px - 120px)
        }

        @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
            margin-left: 264px;
            width: calc(100% - 240px - 48px)
        }

        @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
            padding-top: 0px;
        }

        @media screen and (max-width: $mobile-large) {
            margin-left: 16px;
            width: calc(100% - 32px);
            padding-bottom: 24px;
        }


        .redeem__containerBox-title {
            color: $neutralDark;
            font-size: 40px;
            font-weight: 500;
            margin-bottom: 16px;

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                padding: 0px 16px;
                text-align: center;
                margin-top: 0px;
            }
        }
    
        .redeem__containerBox-promoBox {
            background-color: $primaryDarkBgColor;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            padding: 16px 64px;
    
            h3 {
               
                font-size: 20px;
                color: $neutralLightest;
                font-weight: 600;
                text-align: center;
            }

            span {
                font-size: 20px;
                color: $primaryBlue;
                font-weight: 600;
            }

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                width: 90%;
                padding: 16px 16px;
            }

        }

        .redeem__containerBox-text {
          margin-bottom: 32px;
          width: 100%;
          text-align: center;
          color: $primaryTextColor;
          line-height: 1.7;
          font-size: 18px;

          @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
            padding: 0px 16px;
           }

          @media screen and (min-width: $mobile-large) and (max-width: $tablet)  {
             width: 80%;
          }
        }
    
        .redeem__containerBox_formBox {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            color: $primaryTextColor;
            background-color: $neutralLightest;
            height: auto;
            border-radius: 20px;
            box-shadow: 0 20px 30px rgba(0, 0, 0, .1);
            padding: 20px 40px 40px 40px;
            margin-bottom: 8px;
            width: 100%;

            @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                padding: 16px 16px 32px 16px;
                min-width: auto;
            }

            .RedeemScreen-form {
                width: 100%;
                height: auto;
                display: flex;
                flex-flow: column wrap;
                align-items: flex-start;
                justify-content: center;
            
                @media screen and (max-width: $mobile-large) {
                  width: 100%;
                }
            
                @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
                  width: 100%;
                }
    
                .RedeemScreen-textBelowInputs-container {
                    width: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 4px;
                
                    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                      width: 450px;
                    }
                }
                .RedeemScreen-textBelowInputs {
                    color: $primaryTextColor;
                    width: 90%;
                    line-height: 16px;
                    font-size: 11px;
                    margin-left: 8px;
                
                    @media screen and (max-width: $mobile-large) {
                      width: 90%;
                    }
                
                    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
                      width: 90%;
                    }
                  }
                
                  .RedeemScreen-textBelowInputs.password {
                    width: 100%;
                    margin-top: 4px;
                    margin-left: 0px;
                  }
    
                  .RedeemScreen-wrongPasswordIcon {
                    margin-right: 6px;
                    color: $fdRed;
                    position: relative;
                    top: 2px;
                  }
    
                  .RedeemScreen-termsAndConditions {
                    font-family: $font-stack;
                    color: $primaryTextColor;
                    width: 100%;
                    line-height: 1.5;
                    margin-top: 24px;
                    margin-bottom: 24px;
                    font-size: 13px;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                
                    @media screen and (max-width: $mobile-large) {
                      width: 100%;
                    }
                
                    .RedeemScreen-termsAndConditions__button {
                      border: none;
                      font-family: $font-stack;
                      color: $primaryTextColor;
                      font-size: 13px;
                      text-align: left;
                      line-height: 1.5;
                
                    }
                
                    .RedeemScreen-termsAndConditions__label {
                      position: relative;
                      cursor: pointer;
                      padding-left: 0px;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                      width: 100%;
                      display: flex;
                      flex-flow: row wrap;
                      justify-content: flex-start;
                      align-items: center;
                      position: relative;
                    }
                
                    .RedeemScreen-termsAndConditions__label input[type="checkbox"] {
                      clip: rect(0 0 0 0);
                      padding: 0;
                      width: 1px;
                      height: 1px;
                      overflow: hidden;
                      position: absolute;
                      top: -2px;
                      left: -42px;
                      border: 0;
                    }
                
                    .RedeemScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom {
                      display: block;
                      background-color: $neutralLightest;
                      box-shadow: inset -2px -2px 5px rgba(white, 1),
                      inset 2px 2px 5px rgba(black, 0.1);
                      border-radius: 3px;
                      border: 2px solid white;
                    }
                
                    .checkbox-custom:after {
                      content: "";
                      position: absolute;
                      display: none;
                    }
                
                    /* Show the checkmark when checked */
                    .RedeemScreen-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom:after {
                      display: block;
                    }
                
                    .RedeemScreen-termsAndConditions__label .checkbox-custom:after {
                      position: relative;
                      margin: auto;
                      top: 4px;
                      width: 5px;
                      height: 10px;
                      border: solid $primaryBlue;
                      border-width: 0 3px 3px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg);
                    }
                
                
                    .RedeemScreen-termsAndConditions__checkbox {
                      position: relative;
                      top: -9px;
                      margin-right: 10px;
                      border: none;
                      width: 20px;
                    }
                
                    .checkbox-custom {
                      width: 25px;
                      height: 25px;
                      display: block;
                      background-color: $neutralLightest;
                      box-shadow: -2px -2px 5px rgba(white, 1),
                      2px 2px 5px rgba(black, 0.1);
                      border-radius: 3px;
                      margin-right: 16px;
                      background-size: cover;
                      border: 1px solid #696969;
                    }
                
                    .RedeemScreen-termsAndConditions__label > p {
                      width: 85%;

                      @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
                        width: 75%;
                        }
                    }
                
                
                    .RedeemScreen-termsAndConditions__textLink {
                      color: $primaryBlue;
                    }
                
                  }
    
                  .RedeemScreen-registerButton {
                    @include ctaBtn;
                    margin: auto;
                    margin-top: 16px;
                
                
                    @media screen and (max-width: $tablet) {
                      margin-left: 0;
                      margin-right: 0;
                      width: 100%;
                    }
                  }
                
                  .RedeemScreen-registerButton:hover {
                    background: $blueDark;
                  }
                
                
                  .RedeemScreen-registerButton-Disable {
                    @include ctaBtn-Disable;
                    margin: auto;
                
                
                    @media screen and (max-width: $mobile-large) {
                      width: 100%;
                    }
                  }
            
            }
        }

    }
}