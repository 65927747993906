@import '../../../../constants';

.ProfileScreen-container {
  background-color: $primaryLightBgColor;
  width: 100%;
  height: 100vh;
  margin-top: 80px;
  margin-right: 48px;

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    margin-top: -48px;
  }
}

.ProfileScreen-mainContent {
  margin-left: 356px;
  width: 40%;
  padding-top: 56px;

  @media screen and (min-width: $tablet) and (max-width: $desktop-small) {
    margin-left: 264px;
    width: calc(100% - 240px - 120px)
  }

  @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
    margin-left: 264px;
    width: calc(100% - 240px - 48px)
  }

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    padding-top: 0px;
  }
}

.ProfileScreen-mainContent hr {
  background-color: white;
  border: none;
  height: 1px;
  margin-bottom: 44px;
}

.ProfileScreen-title {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
  margin-bottom: 24px;
  padding-top: 0px;

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    padding-top: 24px;
  }
}

.ProfileScreen-fieldContainer {
  background-color: $neutralLightest;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  border-radius: 13px;
  margin-top: 14px;
}

.ProfileScreen-fieldKey {
  font-weight: bold;
  font-size: 14px;
  color: $primaryTextColor;
  margin-left: 23px;
}

.ProfileScreen-fieldValue {
  font-size: 14px;
  color: $primaryTextColor;
  margin-right: 26px;
  margin-left: 16px;
  text-align: end;
}

.ProfileScreen-dot {
  height: 8px;
  width: 8px;
  background-color: $primaryTextColor;
  border-radius: 50%;
  display: inline-block;
  margin-left: 12px;
}

.ProfileScreen-goToEdit {
  width: 232px;
  height: 50px;
  background-color: $primaryLightBgColor;
  border-radius: 50px;
  border: 1px $primaryLightBgColor solid;
  font-size: 14px;
  font-family: $font-stack;
  font-weight: bold;
  color: $primaryBlue;
  cursor: pointer;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 40px auto 56px;
}

.ProfileScreen-deleteTitle {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
  margin-bottom: 20px;
}

.ProfileScreen-deleteInfo {
  color: $primaryTextColor;
  font-size: 14px;
  margin-top: 21px;
  line-height: 26px;
}

.ProfileScreen-delete {
  width: 232px;
  height: 50px;
  background-color: $fdRed;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $neutralLightest;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 36px auto 48px;
}

.ProfileScreen-manage {
  width: 232px;
  height: 50px;
  background-color: $primaryBlue;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $neutralLightest;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 36px auto 48px;
}

@media screen and (max-width: $mobile-large) {
  .ProfileScreen-mainContent {
    margin-left: 16px;
    width: calc(100% - 32px);
    padding-bottom: 24px;
  }

  .ProfileScreen-container {
    margin-top: 104px;
  }

  .ProfileScreen-fieldValue {
    font-size: 13px;
    color: $primaryTextColor;
    text-align: right;
    margin-left: 16px;
    margin-right: 16px;
  }

  .ProfileScreen-fieldKey {
    font-weight: bold;
    font-size: 13;
    color: $primaryTextColor;
    margin-left: 16px;
  }

  .ProfileScreen-goToEdit {
    width: 100%;
    height: 56px;
  }

  .ProfileScreen-delete {
    width: 100%;
    height: 56px;
  }

  .ProfileScreen-deleteInfo {
    line-height: 1.7;
  }
}



