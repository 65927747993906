@import '../../../../constants';
@import '../../../../buttons';

.SubscriptionScreen-mainContent {
  margin-left: 300px;
  width: calc(100% - 300px);
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin-left: 240px;
    width: calc(100% - 240px);
    height: fit-content;
  }

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    margin-left: 0px;
    width: 100%;
    margin-top: -52px;
  }
}

.SubscriptionScreen-mainContent hr {
  background-color: white;
  border: none;
  height: 1px;
  margin-bottom: 44px;
}

.SubscriptionScreen-title {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
  margin-bottom: 40px;
  padding-top: 18px;
}

/* == NO SUBSCRIPTION ACTIVE SCREEN STYLING */

.SubscriptionScreen-mainContent__Banner {
  width:100%;
  height: 525px;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 56px;


  @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
    height: auto;
  }

  .SubscriptionScreen-mainContent__Banner-PromotionName {
    background-color: $primaryDarkBgColor;
    padding:  8px 16px;
    font-size: 16px;
    font-weight: 600;
    color: $neutralLightest;
    width: 60%;
    text-align: center;
    margin-bottom: 24px;

    .SubscriptionScreen-mainContent__Banner-PromotionName-span{
      color: $primaryBlue;
      font-size: 16px;
      font-weight: 700;
    }

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      width: 100%;
      margin: 32px auto 0px;
    }

    @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
      width: 80%;
      margin: 32px auto -8px;
    }
  }


  .SubscriptionScreen-mainContent__Banner-TextBox {
    width: 500px;
    height: 100%;
    background-color: $neutralLightest;
    display: flex;
    flex-flow: column wrap;
    padding: 0 56px;
    align-content: center;
    justify-content: center;
    order: 1;


  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    height: auto;
    order: 2;
    width: 100%;
    padding: 64px;
  }

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    height: auto;
    order: 2;
    width: 100%;
    padding: 16px;
    margin: 0 auto;
  }

    .SubscriptionScreen-mainContent__Banner-TextBox__PromoTitle {
      font-size: 24px;
      color: $neutralDark;
      font-weight: 600;
      margin-bottom: 24px;
      line-height: 1.3;

      @media screen and (min-width: $mobile-small) and (max-width: $tablet) {
        width: 100%;
        text-align: center;
        padding-top: 32px;
      }
    }

    .SubscriptionScreen-mainContent__Banner-TextBox__Promolist {
      list-style-type: none;
      margin-bottom: 16px;

      @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
        margin-bottom: 32px;
      }

      @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
        order: 3;
        width: 100%;
        margin-bottom: 32px;
      }

      .SubscriptionScreen-mainContent__Banner-TextBox__Promolist-items {
        color: $primaryTextColor;
        font-size: 13px;
        line-height: 1.7;
        margin-bottom: 14px;
        padding-left: 48px;
        position: relative;

        @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
          padding-left: 40px;
        }
      }

      .list-icon {
        position: absolute;
        left: 0px;
        vertical-align: middle;
        top: 50%;
        transform: translate(0, -50%);
        color: #00a21f;
      }
    }

    .SubscriptionScreen-mainContent__Banner-TextBox__Button {
        @include ctaBtn;

        @media screen and (min-width: $mobile-small) and (max-width: $tablet) {
          align-self: center;
          order: 2;
          width: 90%;
          margin-bottom: 32px;
        }
    }

    .SubscriptionScreen-mainContent__Banner-TextBox__Button:hover {
      @include ctaBtn;
      background-color: $blueDark;
  }
  }

  .SubscriptionScreen-mainContent__Banner-Image {
    width: calc(100% - 500px);
    height: 100%;
    background-image: url("../../../../assets/images/account-background.webp");
    background-color: $neutralLightest;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    order: 2;

    @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      height: 400px;
      width: 100%;
      order: 1;
    }

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      height: 280px;
      width: 100%;
      order: 1;
    }
  }
}

.SubscriptionScreen-mainContent__SubscriptionSubtitle {
    width:100%;
    font-size: 24px;
    margin-bottom: 16px;
}


/* == SUBSCRIBE ACTIVE SCREEN STYLING */

.SubscriptionScreenActive-mainContent__Banner {
  width:100%;
  height: 400px;
  margin-bottom: 48px;

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    height: 280px;
    width: 100%;
    margin-bottom: 32px;
  }

  .SubscriptionScreenActive-mainContent__Banner-Image {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/images/account-background.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    order: 1;

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      height: 100%;
      width: 100%;
      order: 1;
    }
  }

}

.SubscriptionScreen-mainContent__SubscriptionInfo {
  width: 70%;
  height: auto;
  margin-bottom: 64px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 95%;
    height: auto;
  }

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    width: 90%;
    margin: 0 auto 48px;
  }

  .SubscriptionScreen-mainContent__SubscriptionInfo__TitleButton {
    width: 100%;
    height: 50%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      margin-top: -16px;
    }

    .SubscriptionScreen-mainContent__SubscriptionInfo__TitleButton-Title {
      font-size: 24px;
      color: $neutralDark;
      font-weight: 600;
      line-height: 1.5;

      @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
        margin: 16px auto 0px;
      }
    }

    .SubscriptionScreen-mainContent__SubscriptionInfo__TitleButton-Button {
      @include ctaBtn;
      width: 216px;
      height: 48px;

      @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
        margin: 16px auto 0px;
      }
    }
  } 

  .SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox {
    width: 100%;
    height: auto;
    background-color: $neutralLightest;
    border-radius: 10px;
    padding: 24px 32px;

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      padding: 24px 16px;
    }

    .SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__activeSubRow {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      color: $primaryTextColor;
      font-size: 16px;

      .SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__activeSubRow-SubsType {
        font-size: 13px;
        font-weight: 600;
      }
    }

    hr {
      border: 1px solid #e2e2e2;
      margin: 0px;
    }

    .SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__NextPaymentRow {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      color: $primaryTextColor;

      .SubscriptionScreen-mainContent__SubscriptionInfo__InfoBox__NextPaymentRow-NextBillText {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }

}


/* == DOWNLOAD LINKS SCREEN STYLING */

.SubscriptionScreen-mainContent__DownloadLink {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
  }


  .SubscriptionScreen-mainContent__DownloadLink-Title{
    font-size: 24px;
    color: $neutralDark;
    font-weight: 600;
    margin-bottom: 16px;
    line-height: 1.3;
    width: 100%;

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      margin-bottom: 40px;
      text-align: center;
    }
  }

  .SubscriptionScreen-mainContent__DownloadLink-iOS{
    margin-right: 32px;
    cursor: pointer;
    width:  auto;
    height: 55px;
    margin-bottom: 24px;

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      margin: 0px 0px 24px 0px;
      height: 50px;
    }
  }

  .SubscriptionScreen-mainContent__DownloadLink-Google{
    cursor: pointer;
    margin-right: 32px;
    width:  auto;
    height: 55px;
    margin-bottom: 24px;

    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      margin: 0px 0px 24px 0px;
      height: 50px;
    }
  }

  .SubscriptionScreen-mainContent__DownloadLink-MacOSButton {
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 24px;
    width:  auto;
    height: 55px;
  
    @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
      margin: 0px 32px 24px 0px;
    }
  
    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      margin: 0px 0px 24px 0px;
      height: 50px;
    }
  }

  .SubscriptionScreen-mainContent__DownloadLink-WindowsButton {
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 24px;
    width:  auto;
    height: 55px;
  
    @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
      margin: 0px 32px 24px 0px;
    }
  
    @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
      margin: 0px 0px 24px 0px;
      height: 50px;
    }
  }

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    width: 90%;
    margin: 0 auto;
    height: auto;
    padding-bottom: 32px;
  }

}



.SubscriptionScreen-mainContent__Banner-SmallTextBox {
  font-size: 11px;
  color: $primaryTextColor;
  width: 100%;
  order: 3;
  padding-right: 56px;
  line-height: 1.7;
  margin-bottom: 32px;
  align-self: flex-end;

  @media screen and (min-width: 1600px) {
    width: 80%;
    order: 3;
  }

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    width: 100%;
    order: 3;
    padding-right: 32px;
  }

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    width: 90%;
    order: 13;
    margin: 16px 8px auto;
    padding-right: 0px;
    text-align: center;

  }
  
}





