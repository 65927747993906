@import "../../constants";
@import "../../buttons";

.modal-container {
  width: 100vw;
  height: 100vh;
  background-color:rgba(100,100,100,0.65) ;
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (min-width: $mobile-small) and (max-width: $desktop-small) {
    padding: 40px 24px;
    position: fixed;
  }
}

.VerifyEmailModal-container {

  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding: 80px 48px;
  height: auto;
  width: auto;
  justify-content: center;

  @media screen and (max-width: $mobile-large) {
    padding: 40px 24px;
    
  }
  .ErrorBox-container {
    margin-top: 16px;
  }
}

.ReactModal__Content {
  background-color: $neutralLightest;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  box-shadow: 0px 50px 50px 1px rgba(0, 0, 0, 0.20);
  opacity: 1;
  max-height: calc(100vh - 48px);
  overflow: scroll;

  @media screen and (max-width: $mobile-large) {
    width: 90%;
    height: auto;
  }
}


.VerifyEmailModal-title {
  font-size: 28px;
  font-weight: 600;
  color: $neutralDark;
  margin-bottom:  10%;
  width: 380px;
  text-align: center;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }
}

.pageStep-counter {
    margin-top: 0px;
    margin-bottom: 16px;
    color: $primaryTextColor;
}


.VerifyEmailModal-body {
  color: $primaryTextColor;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
  width: 380px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }

}


.VerifyEmailModal-body-list {
  list-style-type: none;
  width: 100%;
  height: auto;
  position: relative;
  line-height: 1.8;
  margin-bottom: 8px;
  color: $primaryTextColor;
  padding-left: 24px;

  .VerifyEmailModal-body-list-items {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    .VerifyEmailModal-body-list-bullets {
      width: 8px;
      height: 8px;
      background-color: #C9C9C9;
      border-radius: 50%;
      display: block;
      margin-right: 16px;
    }
  }

  
}

.VerifyEmailModal-form {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    width: 380px;

    @media screen and (max-width: $mobile-large) {
        width: 100%;
      }
    
}

.VerifyEmailModal-container-closeButton{
    width:32px;
    height: 32px;
    margin-bottom: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;

    @media screen and (max-width: $mobile-large) {
      width: 24px;
      height: 24px;
      top: 8px;
      right: 16px;
    }
  
    .VerifyEmailModal-container-closeButton-image {
      width: 32px;
      height: 32px;
      border: none;
      background-size: cover;
      border-radius: 50px;
      box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;
      font-size:30px;
      background-color: $neutralLightest;

      @media screen and (max-width: $mobile-large) {
        ox-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
      }
  
      .VerifyEmailModal-container-closeButton-image-icon {
        font-size: 24px;
        vertical-align: middle;
        position: relative;
        left:1px;
        top:-2px;
        color: $primaryBlue;

        @media screen and (max-width: $mobile-large) {
          font-size: 20px;
          top:-4px;
        }
      }
    }

}

.VerifyEmailModal-code-container{
    display: flex;
    margin-bottom: 0px;
  
    @media screen and (max-width: $mobile-large) {
      width: 100%;
      
    }
  }
  
  .VerifyEmailModal-code{
    background-color: white;
     width: 50px;
    height: 64px;
    border-radius: 10px;
    margin-left: 11px;
    font-size: 24px;
    font-weight: bold;
    color: $primaryTextColor;
    font-family: $font-stack;
  
    @media screen and (max-width: $mobile-large) {
      width: 20%;
      
    }
  }
  
  .VerifyEmailModal-code:nth-child(1) {
    margin-left: 0px;
  }
  
  .VerifyEmailModal-code-container .VerifyEmailModal-code{
    background: #FFFFFF;
    text-align: center
  }


.VerifyEmailModal-verifyButton{
  @include ctaBtn;
  margin-top: 40px;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }
}

.VerifyEmailModal-verifyButton-Disable{
  @include ctaBtn-Disable;
  margin-top: 40px;
  

  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.VerifyEmailModal-resendCodeButton{
  width: 320px;
  height: 56px;
  background-color: $neutralLightest;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $primaryBlue;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: 15px auto 0;

  @media screen and (max-width: $mobile-large) {
    width: 100%;
    
  }
}

