@import '../../constants';

.Feedback-container {
  background-color: white;
  padding: 24px;
  color: $primaryTextColor;
  font-size: 14px;
  font-weight: normal;
  position: relative;
}

.Feedback-header {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}

.Feedback-body {
  margin-bottom: 30px;
  line-height: 24px;
  font-size: 13px;

  @media screen and (max-width: $mobile-large) {
    line-height: 1.7;
  }

}

.Feedback-title {
  color: $neutralDark;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.Feedback-submitted {
  color: $primaryTextColor;
  font-size: 11px;
  font-style: italic;
}

.Feedback-phoneModel {
  color: $primaryTextColor;
  font-size: 11px;
  text-align: right;
  margin-top: 8px;
}

.Feedback-phoneOS {
  color: $primaryTextColor;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
}

.Feedback-delete {
  font-size: 14px;
  font-weight: 500;
  color: $fdRed;
  cursor: pointer;
}
