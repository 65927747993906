@import "../../../constants";

.thank-you-image {
    display: block;
}

.thank-you-title {
    font-size: 24px;
    color:$primaryDarkBgColor;
    font-weight: 600;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;

    @media screen and (max-width: $desktop-small) {
        margin-bottom: 48px;
    }
}

.thank-you-description {
    font-size: 11pt;
    width: 320px;
    margin: auto;
    margin-bottom: 32px
}

.thank-you-container {
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;

  margin-top: 80px;

  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    margin-top: 140px;
  }
}

.thank-you-form {
    width: 375px;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;

    .ErrorBox-container {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.thank-you-termsAndConditions {
    font-family: $font-stack;
    color: $primaryTextColor;
    width: 100%;
    line-height: 1.5;
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 13px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media screen and (max-width: $mobile-large) {
      width: 100%;
    }
}
.thank-you-termsAndConditions__button {
  border: none;
  font-family: $font-stack;
  color: $primaryTextColor;
  font-size: 13px;
  text-align: left;
  line-height: 1.5;

}

.thank-you-termsAndConditions__label {
  position: relative;
  cursor: pointer;
  padding-left: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.thank-you-termsAndConditions__label input[type="checkbox"] {
  clip: rect(0 0 0 0);
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -2px;
  left: -42px;
  border: 0;
}

.thank-you-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom {
  display: block;
  background-color: $primaryLightBgColor;
  box-shadow: inset -2px -2px 5px rgba(white, 1),
  inset 2px 2px 5px rgba(black, 0.1);
  border-radius: 3px;

}

.checkbox-custom:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.thank-you-termsAndConditions__label input[type="checkbox"]:checked ~ .checkbox-custom:after {
  display: block;
}

.thank-you-termsAndConditions__label .checkbox-custom:after {
  position: relative;
  margin: auto;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid $primaryBlue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.thank-you-termsAndConditions__checkbox {
  position: relative;
  top: -9px;
  margin-right: 10px;
  border: none;
  width: 20px;
}

.checkbox-custom {
  width: 25px;
  height: 25px;
  display: block;
  background-color: $neutralLightest;
  box-shadow: -2px -2px 5px rgba(white, 1),
  2px 2px 5px rgba(black, 0.2);
  border-radius: 3px;
  margin-right: 16px;
  background-size: cover;
  border: 1px solid #ffffff;
}

input:focus + .checkbox-custom {
  border: 1px solid #696969;
}

.thank-you-termsAndConditions__label > p {
  width: 85%;
}


.thank-you-termsAndConditions__textLink {
  color: $primaryBlue;
}

.thank-you-termsAndConditions__textLink:focus-within {
  text-decoration: underline;
}



.thank-you-infoIcon {
margin-right: 6px;
position: relative;
margin-top: auto;
margin-bottom: auto;
width: 20px;
font-size: 16px;
color:$primaryTextColor;
}

.thank-you-wrongPasswordIcon {
margin-right: 6px;
color: $primaryTextColor;
position: relative;
font-size: 16px;
margin-top: auto;
margin-bottom: auto;

}

.thank-you-registerButton{
  color: $neutralLightest;
  background-color: $fdGreen;
  height: 48px;
  width: 343px;
  border: none;
  border-radius: 30px;
  margin-bottom: 48px;
  margin-top: 16px;
  box-shadow: -2px -2px 5px rgba(white, 1),
  2px 2px 5px rgba(black, 0.1);
  font-family: $font-stack;
  font-weight: 600;

  @media screen and (max-width: $mobile-large) {
    position: relative;
    bottom: 0px;
    margin: 24px 0px;
    height: 56px;
  }
}

.thank-you-registerButton:focus-within{
background-color: #2e8400;
}

.thank-you-registerButton:hover{
  cursor: pointer;
  background-color: #2e8400;
}


.thank-you-textBelowInputs-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;

    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
      width: 450px;
    }
  }


.thank-you-textBelowInputs {
    color: $primaryTextColor;
    width: 90%;
    line-height: 16px;
    font-size: 11px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: $mobile-large) {
    width: 90%;
    }

    @media screen and (min-width: $mobile-large) and (max-width: 1025px) {
    width: 90%;
    }
}

.thank-you-textBelowInputs.password {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
}

.thank-you-form-container {
    width: 100%;
    text-align: center;
}
