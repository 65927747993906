@import '../../../../constants';

.DeleteAccount-container {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: $primaryLightBgColor;
  padding-top: 48px;

  @media only screen and (max-width: $mobile-large) {
    width: 100%;
    height: auto;
    padding-top: 0px;
  }

  @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    padding-top: 48px;
    padding-bottom: 48px;
    height: auto;
  }

  .DeleteAccount-container-freedrumLogoContainer {
    order: 1;
    width: 100%;
    height: 40px;
    text-align: center;
    margin-bottom: 48px;

    @media only screen and (max-width: $mobile-large) {
      width: 100%;
      order: 1;
      text-align: center;
      margin-top: 48px;
      margin-bottom: 0px;
    }

    @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      width: 100%;
      order: 1;
      text-align: center;
      margin-bottom: 48px;
    }
  }

  .DeleteAccount-dialogBox-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $mobile-large) {
      width: 100%;
      height: auto;
      padding: 20px 16px 48px 16px;
    }

    @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
      height: auto;
    }

    h1 {
      color: $neutralDark;
      font-size: 40px;
      font-weight: 500;

      @media only screen and (max-width: $mobile-large) {
        font-size: 30px;
      }
    }

    p {
      font-size: 18px;
      color: $primaryTextColor;
      margin: 24px 0px 32px 0px;
      line-height: 1.5;
      text-align: center;
      width: 50%;

      @media only screen and (max-width: $mobile-large) {
        font-size: 14px;
        width: 90%;
      }

      @media only screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
        width: 80%;
      }
    }


    .DeleteAccount-dialogBox-form-closeButton {
      width: 320px;
      height: 56px;
      background-color: $primaryLightBgColor;
      border-radius: 50px;
      border: none;
      font-size: 13px;
      text-align: center;
      line-height: 56px;
      font-family: $font-stack;
      font-weight: 700;
      color: $fdRed;
      box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;
      margin: 15px auto 0;

      @media screen and (max-width: $mobile-large) {
        width: 100%;

      }
    }

  }


}