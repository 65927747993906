@import "../../../../constants";

.HowToTestScreen-container {
  background-color: $primaryLightBgColor;
  width: 100%;
  margin-top: 136px;

  @media screen and (max-width: $mobile-large) {
    margin-top: 104px;
  }
}

.HowToTestScreen-mainContent {
  margin-left: 300px;
  padding-top: 18px;

  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    margin-left: 240px;
  }

  @media screen and (max-width: $mobile-large) {
    margin-left: 16px;
  }
}

.HowToTestScreen-title {
  font-size: 18px;
  font-weight: bold;
  color: $neutralDark;
  margin-bottom: 11px;
}

.HowToTestScreen-info {
  font-size: 16px;
  color: $primaryTextColor;
  margin-bottom: 36px;
  padding-right: 120px;
  line-height: 26px;

  @media screen and (min-width: $mobile-large) and (max-width: $tablet) {
    padding-right: 48px;
  }

  @media screen and (max-width: $mobile-large) {
    padding-right: 16px;
    font-size: 14px;
    line-height: 1.7;
  }
}

.HowToTestScreen-instructions-header {
  background-color: $neutralLightest;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 40px;
}

.HowToTestScreen-instructions-header p {
  padding: 20px 32px;
  font-size: 16px;
  font-weight: 700;
  color: $neutralDark;
}

.HowToTestScreen-instruction-semiBold {
  font-weight: 600;
}

.HowToTestScreen-instructions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 48px;

  @media screen and (max-width: $mobile-large) {
    padding-right: 16px;
  }
}

.HowToTestScreen-instructions-container {
  background-color: #ebebeb;
  flex: 42%;
  border-radius: 10px;
  padding-bottom: 38px;
  margin-bottom: 48px;
  justify-content: space-between;
  margin-right: 72px;

  @media screen and (min-width: $mobile-small) and (max-width: $tablet) {
    margin-right: 0;
    flex: 100%;
  }
}


