@import "constants";

@mixin ctaBtn {
  width: 320px;
  height: 56px;
  background-color: $primaryBlue;
  border-radius: 50px;
  border: none;
  font-size: 14px;
  font-family: $font-stack;
  font-weight: 700;
  color: $neutralLightest;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  text-align: center;
}

@mixin ctaBtn-Disable {
  width: 320px;
  height: 56px;
  background-color: $neutralMidDark;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-family: $font-stack;
  font-weight: 700;
  color: $neutralLightest;
  box-shadow: -3px -3px 6px 0 rgba(255, 255, 255, 1), 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  text-align: center;
}

@mixin separatorIcons($imageUrl, $topValue) {
  content: "";
  background: url($imageUrl);
  display: block;
  width: 151px;
  height: 6px;
  position: absolute;
  top: $topValue;
}

@mixin addButton {
  display: inline-block;
  float: right;
  width: 48px;
  height: 48px;
  box-shadow: -3px -3px 3px 0 rgba(255, 255, 255, 1), 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
}
